import React, { useEffect } from 'react';
import { setLogged, setMessageError, setName, setPassword } from '../../slice/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AuthUser } from '../../../../common/axios';
import { Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store';
import { useCookies } from 'react-cookie';
import isMemoryJWT from '../../../../services/isMemoryJWTService';

export default function LoginButton() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const LINK_NAME = process.env.REACT_APP_LINK_NAME;
    const [_logged, setCookieLogged, removeCookieLogged] = useCookies([process.env.REACT_APP_COOKIE_LOGGED!]);
    const [_user, setCookieUser, removeCookieUser] = useCookies([process.env.REACT_APP_COOKIE_USER!]);
    const { name, password } = useSelector((store: RootState) => store.loginSlice);
    
    const handleSignIn = () => {   
        if (!name) {
            dispatch(setMessageError('Введите имя!'));
            return;
        }

        if (!password) {
            dispatch(setMessageError('Введите пароль!'));
            return;
        }

        AuthUser.post('/sign-in', { userName: name,  password })
        .then(({ data }) => {
            const { accessToken, accessTokenExpiration, user } = data;
            isMemoryJWT.setToken(accessToken, accessTokenExpiration);

            setCookieLogged(process.env.REACT_APP_COOKIE_LOGGED!, accessToken);
            setCookieUser(process.env.REACT_APP_COOKIE_USER!, user);
            
            dispatch(setName(''));
            dispatch(setPassword(''));
            dispatch(setLogged(true));
            dispatch(setMessageError(''));
            navigate(`/${LINK_NAME}/clients`); 
        })
        .catch(({ response }) => {
            dispatch(setMessageError(response.data.error));
            removeCookieLogged(process.env.REACT_APP_COOKIE_LOGGED!);
            removeCookieUser(process.env.REACT_APP_COOKIE_USER!);
        })
    }

    // Создание пользователя
    // const handleSignUp = () => {   
    //     if (!name || !password ) return;

    //     AuthUser.post('/sign-up', { userName: name, password, role: 1 })
    //     .then(({ data }) => {
    //         const { accessToken, accessTokenExpiration } = data;
    //         isMemoryJWT.setToken(accessToken, accessTokenExpiration);
    //         dispatch(setLogged(true));
    //     })
    //     .catch(err => console.log(err.message))
    // }

    return (
        <Flex justifyContent={'center'} alignItems={'center'} mt={3}>
            <Button 
                w={'138px'} 
                h={'46px'} 
                fontSize={'27px'} 
                color={'var(--color-grayBlack)'}
                variant={'standard'} 
                onClick={handleSignIn}
            >
                Вход
            </Button>
        </Flex>
  );
}
