import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TypeCompanyContact } from '../../../../types';
import { Flex, Text, Textarea } from '@chakra-ui/react';
import { RootState } from '../../../../../../store';
import tableClientsSlice from '../../../../slices/tableClientsSlice';

type Props = {
    title: string,
    field: string,
} 

export default function TextareaTextField(props: Props) {
    const dispatch = useDispatch();
    const { companyContact } = useSelector((state: RootState) => state.tableClientsSlice);

    const value = (companyContact as any)[props?.field!] as keyof TypeCompanyContact; 
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => dispatch(tableClientsSlice.actions.setCompanyContact({ [props?.field]: e.currentTarget.value }));

    return (
        <Flex flexDirection={'column'} w={'280px'}>
            <Text fontWeight={'bold'} color={'var(--color-grayBlack)'}>{props?.title}: </Text>
            <Textarea 
                fontSize={'16px'} 
                variant={'base'}
                value={value ?? ''}
                onChange={onChange} 
                background={'var(--color-gray)'}
                border={'2px solid var(--color-grayBlack)'}
            /> 
        </Flex>
    )    
}
