import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Option } from '../../../common/utils';
import { TypeTeam } from '../types';

const name = 'teamSlice' as 'teamSlice';

export type InitialTeamSlice = {
    searchMember: string,
    team: TypeTeam[],
    member: TypeTeam,
    countryOptions: Option[],
    statusesTeamOptions: Option[],
    selectedMemberId: number | null,
    isShowDeleteMemberPopup: boolean,
}

const initialState: InitialTeamSlice = {
    searchMember: '',
    team: [],
    member: {},
    countryOptions: [ { value: '', label: '' } ],
    statusesTeamOptions: [ { value: '', label: '' } ],
    selectedMemberId: null,
    isShowDeleteMemberPopup: false,
}

const teamSlice = createSlice({
    name,
    initialState,
    reducers: {  
        setSearchMember: (state, action: PayloadAction<InitialTeamSlice['searchMember']>) => {
            state.searchMember = action.payload;
        },  
        setTeam: (state, action: PayloadAction<InitialTeamSlice['team']>) => { 
            state.team = action.payload;
        },
        setMember: (state, action: PayloadAction<InitialTeamSlice['member']>) => {
            state.member = { ...state.team, ...action.payload } 
        }, 
        setCountryOptions: (state, action: PayloadAction<InitialTeamSlice['countryOptions']>) => {
            state.countryOptions = action.payload;
        },
        setStatusesTeamOptions: (state, action: PayloadAction<InitialTeamSlice['statusesTeamOptions']>) => {
            state.statusesTeamOptions = action.payload;
        },
        setSelectedMemberId: (state, action: PayloadAction<InitialTeamSlice['selectedMemberId']>) => {
            state.selectedMemberId = action.payload;
        },
        setIsShowDeleteMemberPopup: (state, action: PayloadAction<InitialTeamSlice['isShowDeleteMemberPopup']>) => {
            state.isShowDeleteMemberPopup = action.payload;
        },
        clearMember: (state, action: PayloadAction<InitialTeamSlice['member']>) => { 
            state.member = {} 
        },
    },
});

const getState = (state: any) => state[name] as InitialTeamSlice;

export default {
    ...teamSlice,
    selectors: {
        getState,
    }
}
