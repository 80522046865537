import React, { ChangeEvent } from 'react';
import { PropsField } from '..';
import EditSelectOrTextField from '../../../../../components/EditSelectOrTextField';

export default function SelectField(props: PropsField) {
    const handleSelect = (e: any) => props.field.onChange(e?.value);
    const value = props.options.filter(option => option.value === props.field.value)[0];
    
    return (
        <EditSelectOrTextField
            bg={props.bg} 
            id={props.id}
            padding={'0 0 0 10px'}
            title={props.title}
            value={value as any}
            onChange={handleSelect}
            options={props.options}
            placeholder={props.placeholder}
        />
    )
}
