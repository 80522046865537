import { ChangeEvent } from 'react';
import { PropsField } from '..';
import EditInputOrLinkField from '../../../../../components/EditInputOrLinkField';

export default function LinkInputField(props: PropsField) {
    const handleField = (e: ChangeEvent<HTMLInputElement>) => props.field.onChange(e.currentTarget.value);

    return (
        <EditInputOrLinkField 
            bg={props.bg}
            id={props.id} 
            value={props.field.value ?? ''} 
            onChange={handleField} 
            title={props.title}
        />
    )
}
