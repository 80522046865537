import React from 'react';
import { statusOption } from '../../../../../../common/options/statusOption';
import { TypeCompanyTasksOrStatus } from '../../../../types';
import { Option } from '../../../../../../common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ru } from 'date-fns/locale';
import { format } from "date-fns";
import tableClientsSlice from '../../../../slices/tableClientsSlice';
import BaseSelect from '../../../../../../components/BaseSelect';

export default function StatusTask() {
  const dispatch = useDispatch();
  const { company, companyTasksOrStatuses } = useSelector((state: RootState) => state.tableClientsSlice);
  const handleStatus = (e: Option | any) => dispatch(tableClientsSlice.actions.setCompany({ status: e?.value }));

  const typeColorTaskAndStatus = {
    task: 'var(--color-purple)',
    status: 'var(--color-grayBlack)',
  }
    
  return (
    <Box w={'730px'} h={'737px'} overflowY={'auto'} p={'10px'} bg={'var(--color-grayWhite)'} borderRadius={'25px'} border={'2px solid var(--color-grayBlack)'}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex columnGap={4}>
          <Text color={'var(--color-grayBlack)'} fontSize={'32px'} mb={'14px'}>Дата</Text>
          <Text color={'var(--color-grayBlack)'} fontSize={'32px'} mb={'14px'}>статус/задачи</Text>
        </Flex>
        <BaseSelect
          width={'320px'}
          menuWidth={'320px'}
          options={statusOption}
          value={company?.status ?? 1}
          onChange={handleStatus}
          border={'none'}
          background={''}
          borderHover={'none'}
          borderBottom={'none'}
          borderHoverBottom={'none'}
          placeholder={' '}
        />
      </Flex>
      <Flex flexDirection={'column'}>
        {companyTasksOrStatuses?.map((item: TypeCompanyTasksOrStatus, index: number) => {
          return (
            <Flex 
              key={item.id} 
              h={'64px'} 
              p={'15px'}
              alignItems={'center'} 
              background={index % 2 ? 'var(--color-grayWhite)' : 'var(--color-gray)'}
              columnGap={4}
            >
              <Text fontSize={14} color={'var(--color-grayBlack)'}>
                {format(new Date(item.create_datetime ?? ''), "dd.MM.yyyy", { locale: ru })}
              </Text>
              <Text fontWeight={600} fontSize={14} color={typeColorTaskAndStatus[item.type ?? 'status']}>{item.text}</Text>
            </Flex>
          )
        })}
      </Flex>
    </Box>
  )
}