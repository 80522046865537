import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import DatePickerCustomField from '../../../components/DatePickerCustomField';
import styles from '../fields/styles/TaskOrStatusDateField.module.scss';

type Props = {
    field?: any,
}

export default function TaskOrStatusDateFeild(props: Props) {
    return (
        <Flex flexDirection={'column'} w={'320px'}>
            <Text color={'var(--color-grayBlack)'} fontWeight={'bold'}>Дата</Text>
            <DatePickerCustomField 
                value={props.field.value ?? new Date()} 
                onChange={(date: Date) => props.field.onChange(date)}
                className={styles}
                isShowTimeSelect={true}
            />
        </Flex>
    )
}
