import { Column } from "../../../../../common/utils";
import TextRenderer from "../../../../../components/TableData/renders/TextRenderer";
import ImportantCompanyRenderer from "./renders/ImportantCompanyRenderer";
import TitleAction from "../../../../../common/TableActions/TitleAction";
import ActionsCompanyRenderer from "./renders/ActionsCompanyRenderer";
import ImportantAction from "./actions/ImportantAction";

export const columns = [
    { data: 'is_important', title: 'Все', width: '40px', renderer: ImportantCompanyRenderer, action: ImportantAction },
    { data: 'company', title: 'Компания', width: '120px', renderer: TextRenderer, action: TitleAction },
    { data: 'country', title: 'Страна', width: '80px', renderer: TextRenderer, action: TitleAction },
    { data: 'industry', title: 'Отрасль', width: '80px', renderer: TextRenderer, action: TitleAction },
    { data: 'status', title: 'Статус', width: '50px', renderer: TextRenderer, action: TitleAction },
    { data: 'actions', title: 'Действия', width: '120px', renderer: ActionsCompanyRenderer, action: TitleAction },
] as Column[];