import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditTextareaOrTextField from '../../../../../../../components/EditTextareaOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';


export default function AddressField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleAddress = (e: ChangeEvent<HTMLTextAreaElement>) => dispatch(tableClientsSlice.actions.setCompany({ address: e.currentTarget.value }));
 
    return (
        <EditTextareaOrTextField
            bg={props.bg}
            id={company?.id}
            title={'Адрес/телефон'}
            value={company?.address}
            onChange={handleAddress}
        />
    )
}
