import React from 'react';
import { Button, ButtonGroup, Text } from '@chakra-ui/react';
import { CompanyContacts } from '../../../common/axios';
import { useDispatch, useSelector } from 'react-redux';
import { TypeCompanyContact } from '../types';
import { RootState } from '../../../store';
import tableClientsSlice from '../slices/tableClientsSlice';
import Popup from '../../../components/Popup';
import _ from 'lodash';

export default function DeleteCompanyContactPopup() {
    const dispatch = useDispatch();
    const { 
        company, 
        companyContacts, 
        selectedCompanyContact, 
        isShowDeleteCompanyContacntPopup, 
    } = useSelector((state: RootState) => state.tableClientsSlice);
 
    const handleClose = () => {
        dispatch(tableClientsSlice.actions.setShowDeleteCompanyContactPopup(false));
        dispatch(tableClientsSlice.actions.setSelectedCompanyContact(null));
    };

    const deleteCompanyContact = () => {
        if (!selectedCompanyContact || !company) return;

        const clearCompanyContact = companyContacts.filter(companyContact => companyContact.name !== selectedCompanyContact.name);
        
        if (selectedCompanyContact.id) {
          CompanyContacts.post('/delete', { companyContactId: selectedCompanyContact.id, companyId: company.id })
          .then(({ data }) => {
            const { companyContacts } = data;
            const newAndOldCompanyContacts = clearCompanyContact.concat(companyContacts).filter((companyCont: TypeCompanyContact) => companyCont);
            const unigCompanyContacts = _.uniqBy(newAndOldCompanyContacts, 'id') as TypeCompanyContact[];
            
            dispatch(tableClientsSlice.actions.setCompanyContacts(unigCompanyContacts));
          })
          .catch(err => console.log(err))
        } else {
            dispatch(tableClientsSlice.actions.setCompanyContacts(clearCompanyContact));
        }
        handleClose();
    };

    return (
        <Popup
            header={'Удаление контакта'}
            border={'2px solid var(--color-purple)'}
            borderRadius={'15px'}
            isOpen={isShowDeleteCompanyContacntPopup}
            setOpen={handleClose}
            footer={
                <ButtonGroup>
                    <Button  
                        fontSize={'17px'} 
                        variant={'standard'} 
                        onClick={handleClose}
                    >
                        Отменить
                    </Button>
                    <Button 
                        fontSize={'17px'} 
                        variant={'fill'} 
                        onClick={deleteCompanyContact}
                    >
                        Удалить
                    </Button>
                </ButtonGroup>
            }
        >
            <Text color={'var(--color-grayBlack)'}>
                Вы уверены что, хотите удалить этот контакт?
            </Text>
        </Popup>
    )
}