import TitleAction from "../../../../../common/TableActions/TitleAction";
import { Column } from "../../../../../common/utils";
import TextRenderer from "../../../../../components/TableData/renders/TextRenderer";
import ActionsTeamRenderer from "./renders/ActionsTeamRenderer";

export const columns = [
    { data: 'name', title: 'Имя', width: '120px', renderer: TextRenderer, action: TitleAction },
    { data: 'country', title: 'Страна', width: '80px', renderer: TextRenderer, action: TitleAction },
    { data: 'speciality', title: 'специальность', width: '80px', renderer: TextRenderer, action: TitleAction },
    { data: 'status', title: 'Статус', width: '50px', renderer: TextRenderer, action: TitleAction },
    { data: 'actions', title: 'Действия', width: '120px', renderer: ActionsTeamRenderer, action: TitleAction },
] as Column[];