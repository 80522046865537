import React from 'react'
import { PropsField } from '..';
import EditDateOrTextField from '../../../../../components/EditDateOrTextField';
import styles from './fieldsStyles/EditDateOrTextField.module.scss';

export default function DateField(props: PropsField) {
    const handleBirthday = (e: any) => props.field.onChange(e);

    return (
        <EditDateOrTextField
            bg={props.bg}
            id={props.id} 
            title={props.title}
            value={props.field.value ?? new Date()} 
            onChange={handleBirthday} 
            className={styles}
        />
  )
}