import { configureStore } from '@reduxjs/toolkit';
import loginSlice, { InitialLoginState } from '../pages/Login/slice/loginSlice';
import pageClientsSlice, { InitialPageClientsSlice } from '../pages/Clients/slices/pageClientsSlice';
import tableClientsSlice, { InitialTableClientsSlice } from '../pages/Clients/slices/tableClientsSlice';
import companyClientsSlice, { InitialCompanyClientsSlice } from '../pages/Clients/slices/companyClientsSlice';
import calendarTasksOrStatusesSlice,  { InitialCalendarTasksOrStatusesSlice} from '../pages/CalendarTaskOrStatus/slices/calendarTasksOrStatusesSlice';
import teamSlice, { InitialTeamSlice } from '../pages/Team/slices/teamSlice';

export type RootState = {
  teamSlice: InitialTeamSlice,
  loginSlice: InitialLoginState,
  pageClientsSlice: InitialPageClientsSlice,
  tableClientsSlice: InitialTableClientsSlice,
  companyClientsSlice: InitialCompanyClientsSlice,
  calendarTasksOrStatusesSlice: InitialCalendarTasksOrStatusesSlice,
}

export default configureStore({
  reducer: { 
    loginSlice,
    pageClientsSlice,
    teamSlice: teamSlice.reducer,
    tableClientsSlice: tableClientsSlice.reducer,
    companyClientsSlice: companyClientsSlice.reducer,
    calendarTasksOrStatusesSlice: calendarTasksOrStatusesSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});