import React from 'react';
import { CompanyTaskOrStatuses } from '../../../common/axios';
import { Controller, useForm } from 'react-hook-form';
import { Button, Flex} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import calendarTasksOrStatusesSlice from '../slices/calendarTasksOrStatusesSlice';
import TaskOrStatusCompanyField from '../fields/TaskOrStatusCompanyField';
import TaskOrStatusActiveField from '../fields/TaskOrStatusActiveField';
import TaskOrStatusTypeField from '../fields/TaskOrStatusTypeField';
import TaskOrStatusTextField from '../fields/TaskOrStatusTextField';
import TaskOrStatusDateFeild from '../fields/TaskOrStatusDateFeild';
import Popup from '../../../components/Popup';
import toast from 'react-hot-toast';

type Props = {
    isOpen: boolean,
    setOpen: (value: boolean) => void,
    defaultValues: {
        id: number,
        type: string,
        is_active: number|string,
        text: string,
        appointment_datetime: Date,
        company_id: number,
    }
    setDefaultValuesTaskOrStatus: (value: any) => void,
 }

export default function AddTaskOrStatus(props: Props) {
    const dispatch = useDispatch();
    const [_cookie] = useCookies([process.env.REACT_APP_COOKIE_USER!]);
    const { register, handleSubmit, reset, control } = useForm({
        defaultValues: props.defaultValues,
        values: props.defaultValues
    });

    const handleClose = () => {
        reset({ 
            type: 'status',
            is_active: 1,
            text: '',
            appointment_datetime: new Date(),
            company_id: 0,
        });
        props.setDefaultValuesTaskOrStatus({});
        props.setOpen(false);
    }; 

    const submitForm = (taskOrStatus: any,  event: any) => {
        event.preventDefault();

        CompanyTaskOrStatuses.post('/save', { 
            companyTasksOrStatuses: [taskOrStatus] ?? [],
            craeteUserId: _cookie?._UCAR?.id ?? 0,
        })
        .then(({ data }) => {
            const { message, allCompanyTasksOrStatuses } = data;

            dispatch(calendarTasksOrStatusesSlice.actions.setCompanyTasksOrStatuses(allCompanyTasksOrStatuses));
            toast.success(message);
            handleClose(); 
          
        })
        .catch(err => toast.error(err.response.data));
    };

    const deleteTaskOrStatus = () => {
        CompanyTaskOrStatuses.post('/delete', { companyCompanyTasksOrStatusesId: props.defaultValues.id })
        .then(({ data }) => {
            const { message, allCompanyTasksOrStatuses } = data;

            dispatch(calendarTasksOrStatusesSlice.actions.setCompanyTasksOrStatuses(allCompanyTasksOrStatuses));
            toast.success(message);
            handleClose(); 
        })
        .catch(err => toast.error(err.response.data));
    }

    return (
        <Popup
            header={'Добавление задачи или статуса'}
            isOpen={props.isOpen}
            border={'2px solid var(--color-purple)'}
            borderRadius={'15px'}
            setOpen={handleClose}
            sizePopup={'2xl'}
            footer={<></>}
        >   
            <form onSubmit={handleSubmit(submitForm)}>
                <Flex flexDirection={'column'} rowGap={3}>
                    <Flex columnGap={8}>
                        <TaskOrStatusTypeField register={{ ...register('type') }}/>
                        <TaskOrStatusActiveField register={{ ...register('is_active') }}/>
                    </Flex>
                    <Flex alignItems={'center'} columnGap={4}>
                        <Controller
                            control={control}
                            name={'appointment_datetime'}
                            render={({ field }) => <TaskOrStatusDateFeild field={field}/>}
                        />
                        <Controller
                            control={control}
                            name={'company_id'}
                            render={({ field }) => <TaskOrStatusCompanyField field={field}/>}
                        />
                    </Flex>
                    <TaskOrStatusTextField register={{ ...register('text') }}/>
                    <Flex justifyContent={'space-between'} alignItems={'center'} mb={-7} mt={3}>
                        <Flex>
                            {props?.defaultValues.id && <Button fontSize={'17px'} fontWeight={600} color={'var(--color-red)'} borderColor={'var(--color-red)'} variant={'standard'} onClick={deleteTaskOrStatus}>Удалить</Button>}
                        </Flex> 
                        <Flex columnGap={2} justifyContent={'flex-end'}>
                            <Button fontSize={'17px'} variant={'standard'} onClick={handleClose}>Отменить</Button>
                            <Button type='submit' fontSize={'17px'} variant={'fill'}>{props?.defaultValues.id ? 'Применить' : 'Добавить'} </Button> 
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </Popup>
    )
}