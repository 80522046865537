import { useEffect, useState } from 'react';
import starsVideo from '../../assets/videos/StarsVideo.mp4';
import s from './Landing.module.css';

export default function Landing() {
    const [isAvailableVideo, setIsAvailableVideo] = useState(false);

    useEffect(() => {
        let video = document.getElementById("video") as HTMLVideoElement;
        if (video) {  
           video.ontimeupdate = () => setIsAvailableVideo(true);
       }      
    }, []);

    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <video id={'video'} className={s.video} autoPlay loop muted>
                    <source src={starsVideo} type="video/mp4"/>
                </video> 
                {!isAvailableVideo && <div className={s.templateVideo} />}  
                <div className={s.content}>
                    <h3 className={`${s.titleOne} ${s.title}`}>Идейное бюро</h3>
                    <div className={`${s.titleTwo} ${s.title}`}>
                        <h3>#ОДИН</h3>
                        <p>Рожденное под белорусским небом с любовью к идеям и людям</p>
                    </div>
                </div>
            </div>
        </div>
    )
}