import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';
import { Twirl as Hamburger } from 'hamburger-react';
import { PropsContactPopup } from '../..';
import { Link } from 'react-scroll';
import s from './Header.module.css';

type ListLink = {
    id: number,
    text: string,
    to: string,
}

export default function Header(props: PropsContactPopup) {
    const [isShowBurger, setShowBurger] = useState(false);
    const handleMenuBurger = () => setShowBurger(prev => !prev);

    const listLinks: ListLink[] = [
        { id: 1, text: 'Услуги', to: 'services' },
        { id: 2, text: 'О нас', to: 'aboutUs' },
        { id: 3, text: 'Контакты', to: 'contacts' },
    ];

    return (
        <div className={s.wrapper}>
            <header className={s.header}>
                <Logo className={isShowBurger ? `${s.logo} ${s.active}`: s.logo} />
                <div className={s.rightBlock}>
                    <div className={s.listLinks}>
                        {listLinks.map(link => (
                            <Link key={link.id} to={link.to} className={s.link} smooth={true} duration={1600}>
                                {link.text}
                            </Link>
                        ))}
                    </div>
                    <button onClick={props.showContactPopup} className={s.button}>
                        <p>Связаться</p>
                    </button>
                </div>
                <div className={s.burgerBlock}>
                    <Hamburger size={18} toggled={isShowBurger} toggle={handleMenuBurger}/>
                    <div className={isShowBurger ? `${s.containerBurger} ${s.active}` : s.containerBurger}>
                        <div className={s.listBurgerLinks}>
                            {listLinks.map(link => (
                                <Link 
                                    key={link.id} 
                                    to={link.to} 
                                    className={s.linkBurger} 
                                    smooth={true} 
                                    duration={1600}
                                    onClick={handleMenuBurger}
                                >
                                    {link.text}
                                </Link>
                            ))}
                        </div> 
                    </div>
                </div>
            </header>
        </div>
    )
}