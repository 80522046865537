import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';
import styles from './PhoneCustomField.module.scss';

type PropsPhone = {
    value: string,
    onChange: any;
    onlyCountries?: string[],
    placeholder?: string,

    //style
    containerClass?: string,
    inputClass?: string,
    dropdownClass?: string,
    searchClass?: string,
    buttonClass?: string,
}

export default function PhoneCustomField(props: PropsPhone) {
  return (
    <PhoneInput
      country={'by'}
      localization={ru}
      onlyCountries={props.onlyCountries ? props.onlyCountries : ['by', 'ru', 'kz', 'uz']}
      placeholder={props.placeholder ? props.placeholder : '' }
      containerClass={props.containerClass ? props.containerClass : styles.containerClass}
      inputClass={props.inputClass ? props.inputClass : styles.inputClass}
      dropdownClass={props.dropdownClass ? props.dropdownClass : styles.dropdownClass}
      searchClass={props.searchClass ? props.searchClass : styles.searchClass}
      buttonClass={props.buttonClass ? props.buttonClass : styles.buttonClass}
      value={props.value}
      onChange={props.onChange}
    />
  )
}
