import React from 'react';
import { Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Column } from '../../common/utils';
import _ from 'lodash';

export default function TableData({ columns, rows }: any) {
    const tableRows = rows();
    const tableColumns = columns ?? [];
    const grouByColumnsData = _.groupBy(tableColumns, col => col.data) ?? [];

    return (
        <Flex bg={'gray'} backgroundSize={'cover'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
            <Flex p={'15px'} bg={'var(--color-grayWhite)'} border={'2px solid var(--color-grayBlack)'} borderRadius={'25px'}>
                <TableContainer overflowY={'auto'} w={'1200px'} h={'655px'}>
                    <Table size='md' variant='striped'>
                        <Thead position="sticky" top={0} bg={'var(--color-grayWhite)'} border={'none'}>
                            <Tr>
                                {tableColumns.map((column: Column) => (
                                    <Th 
                                        key={column.data}
                                        width={column.width} 
                                        color={'var(--color-purple)'} 
                                    >
                                        {column.action(column.title)}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tableRows?.map((row: any, index: number) => (
                                    <Tr 
                                        key={row.id}  
                                        color={'var(--color-grayBlack)'} 
                                        background={(index && index % 2) ? 'var(--color-grayWhite)' : 'var(--color-gray)'}
                                    >
                                        {Object.keys(row).map((item: any, index : number) => {
                                            if (item === 'id') return;
                                            return ( 
                                                <Td key={row.id + index} border={'none'}>
                                                    {grouByColumnsData[item][0]?.renderer(row, item)}
                                                </Td>
                                        )})}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Flex>
    )
}