import React from 'react';
import { Toaster } from 'react-hot-toast';
import styles from './Toast.module.scss';

export default function Toster() {   
    return(
        <Toaster 
            toastOptions={{ 
                className: styles.toaster,
                success: {
                    style: {
                        color: 'var(--color-purple)',
                    },
                    icon: ''
                },
                error: {
                    style: {
                        color: 'var(--color-red)',
                    },
                    icon: ''
                },
            }}
        />
    ) 
}