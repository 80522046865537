import React, { useEffect, useState } from 'react'
import { ReactComponent as EditSVG } from '../../common/icons/edit.svg';
import { ReactComponent as LockSVG } from '../../common/icons/lock.svg';
import { Flex, Text, Button } from '@chakra-ui/react';
import { Option } from '../../common/utils';
import BaseSelect from '../BaseSelect';

type Props = {
    bg: string,
    id: number | undefined,
    title: string,
    value: Option | undefined,
    onChange: any,
    options: Option[],
    placeholder: string, 
    padding?: string,
}

export default function EditSelectOrTextField(props: Props) {
    const [onlyRead, setOnlyRead] = useState(false);
    const handleOnlyRead = () => setOnlyRead(prev => !prev);

    useEffect(() => {
        if (!props.id) setOnlyRead(true)
        else setOnlyRead(false)
    }, [props.id]);

    return (
        <Flex alignItems={'center'} p={'5px 10px'} rowGap={2} bg={props.bg} justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
                {onlyRead ?   
                    <>
                        <Text fontSize={'16px'} color={'var(--color-grayBlack)'}>{props.title}:</Text>
                        <BaseSelect 
                            width={'320px'}
                            menuWidth={'320px'}
                            options={props.options}
                            value={props.value}
                            onChange={props.onChange}
                            border={'none'}
                            background={props.bg}
                            borderHover={'none'}
                            borderBottom={'none'}
                            borderHoverBottom={'none'}
                            placeholder={ props.placeholder}
                            padding={props.padding}
                        />
                    </>
                    :
                    <Flex alignItems={'center'} fontSize={'16px'} color={'var(--color-grayBlack)'} minH={'40px'}>
                        <Text>{props.title}:</Text>
                        <Text width={'260px'} pl={'10px'}>{props.value?.label}</Text> 
                    </Flex>
                }
            </Flex>
            {props.id && <Button onClick={handleOnlyRead} variant={'empty'}>
                {onlyRead ? 
                    <LockSVG width={'25px'} height={'25px'} color={'var(--color-purple)'}/> 
                    : 
                    <EditSVG width={'25px'} height={'25px'} fill={'var(--color-purple)'}/> 
                }
            </Button>}
        </Flex>
    )
}