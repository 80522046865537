import React from 'react'
import { countriesOption } from '../../../../../../../common/options/countryOption';
import { Option } from '../../../../../../../common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditSelectOrTextField from '../../../../../../../components/EditSelectOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function CountryField(props: propsField) {
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.tableClientsSlice);
  const handleCountry = (e: Option|any) => dispatch(tableClientsSlice.actions.setCompany({ country: e?.value }));
  const selectCountry = countriesOption.filter((o: Option) => (o.value === company?.country as any))[0];

  return (
    <EditSelectOrTextField
      bg={props.bg} 
      id={company?.id}
      title={'Страна'}
      value={selectCountry}
      onChange={handleCountry}
      options={countriesOption}
      placeholder={'Выберите страну'}
    />
  )
}