import React, { useMemo } from 'react';
import { Option } from '../../common/utils';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import ReactSelect from "react-select";

type Props<TOption extends Option> = {
    name?: string,
    options: Option[],
    value?: TOption|null|undefined|TOption['value'],
    onChange?: ((option: TOption|null|undefined) => void),
    loadOptions?: (inputValue:string, callback:(options: TOption[]) => void) => void,
    placeholder?: string,
    label?: string|JSX.Element,
    isMulti?: boolean,
    closeMenuOnSelect?: boolean,
    creatable?: boolean,
    isDisabled?: boolean,

    // styles
    width?: string|number,
    height?: string|number,
    minHeight?: string|number,
    menuWidth?: string|number,
    menuPosition?: string|number,
    padding?: string|number,
    background?: string,
    munuBackground?: string,
    colorMenu?: string,
    dropdownIndicatorColor?: string
    backgroundColor?: string,
    border?: string,
    borderBottom?: string,
    borderRadius?: string,
    borderHover?: string,
    borderHoverBottom?: string,
}

export default function BaseSelect<TOptions extends Option>(props: Props<TOptions>) {

    const styles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'var(--color-purple)' : 'var(--color-purple)',
            width: props.width ? props.width : '320px',
            height: props.height ? props.height : '40px',
            minHeight: props.minHeight ? props.minHeight : '40px',
            border: props.border ? props.border : '2px solid var(--color-purple)',
            borderBottom: props.borderBottom ? props.borderBottom : '2px solid var(--color-purple)',
            borderRadius: props.borderRadius ? props.borderRadius : '25px',
            background: props.background ? props.background : 'var(--color-grayWhite)',
            boxShadow: 'none',
            '&:hover': {
                border: props.borderHover ? props.borderHover : '2px solid var(--color-purple)',
                borderBottom: props.borderHoverBottom ? props.borderHoverBottom : '2px solid var(--color-purple)',
         }
        }),
        menu: (baseStyles: any, state: any) => ({
            ...baseStyles,
            width: props.menuWidth ? props.menuWidth : '320px',
            background: props.munuBackground ? props.munuBackground : 'var(--color-grayWhite)',
            color: props.colorMenu ? props.colorMenu : 'var(--color-grayBlack)',
        }),
        menuList: (baseStyles: any, state: any) => ({
            ...baseStyles,
        }),
        singleValue: (baseStyles: any, state: any)=> ({
            ...baseStyles,
            color: 'var(--color-grayBlack)',
        }),
        option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? "var(--color-gray)" : "",
            "&:hover": {                                          
                color: 'var(--color-purple)',                         
            },
        }),
        valueContainer: (baseStyles: any, state: any) => ({
            ...baseStyles,
            height: props.height ? props.height : '40px',
            padding: props.padding ? props.padding : '0 0 6px 8px',
        }),
        indicatorsContainer: (baseStyles: any, state: any) => ({
            ...baseStyles,
            height: props.height ? props.height : '40px',
        }),
    }

    const SelectComponent =  props.loadOptions ? (props.creatable ? AsyncCreatable : AsyncSelect) : (props.creatable ? Creatable: ReactSelect) as any;

    const value = useMemo(() => {
        const value = props.value;
        if (props.loadOptions || props.isMulti) return value;
        else if (value === undefined) return null;
        else if (value === null) return null;
        else if (typeof value === 'object' && 'value' in value) return value;
        else return props.options?.find(o => o.value === value);
      }, [props.value]);

    return (
        <SelectComponent 
            loadOptions={props.loadOptions}
            styles={styles}
            noOptionsMessage={() => 'Не найдено'}
            loadingMessage={() => 'загрузка...'}
            formatCreateLabel={(val: string) => `Добавить ${val}`}
            {...props}
            value={value}
            isClearable={true}

            // formatCreateLabel={(val: string) => `Добавить ${val}`}
            placeholder={props.placeholder ? props.placeholder : 'Выберите...'}
            menuPosition={props.menuPosition ?? 'fixed'}
            
         
      
        />
    )
}
