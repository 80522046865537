export type CreatableModel<T> = T | { [k in keyof T]?: k extends 'id' ? undefined : T[k] };
export type Bit = 0 | 1;
export type Option = { 
    value: string | number, 
    label: string, 
}

export const transformToArray = (obj: {[key: string]: string}) => {
    if (!obj) return [];
    
    return Object.keys(obj).map(status => {
        const value = obj[status];

        return ({ value: status, label: value });
    });
}

export type Column = {
    data: string,
    title: string,
    width: string,
    renderer: (row: any, ...args: any) => void,
    action: (title: string) => JSX.Element,
}
