import React, { ChangeEvent } from 'react';
import { PropsField } from '..';
import EditTextareaOrTextField from '../../../../../components/EditTextareaOrTextField';

export default function TextareaField(props: PropsField) {
  const handleField = (e: ChangeEvent<HTMLTextAreaElement>) => props.field.onChange(e.currentTarget.value);

  return (
    <EditTextareaOrTextField
      bg={props.bg}
      id={props.id} 
      value={props.field.value ?? ''} 
      onChange={handleField} 
      title={props.title}
    />
  )
}
