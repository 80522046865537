import { useEffect, useState, useRef } from 'react';
import { ReactComponent as EditSVG } from '../../common/icons/edit.svg';
import { ReactComponent as LockSVG } from '../../common/icons/lock.svg';
import { Flex, Text, Input, Button, Link } from '@chakra-ui/react';

type Props = {
    bg: string,
    id: number|undefined,
    title: string,
    accept?: string,
    value: File|undefined,
    onChange: any,
}

export default function FileField(props: Props) {
    const [onlyRead, setOnlyRead] = useState(false);
    const filePicker = useRef<HTMLInputElement>(null);
    const hrefDownloadFile = props?.value?.name ? URL.createObjectURL(props.value) : '';

    const handleOnlyRead = () => setOnlyRead(prev => !prev);
    const handlePicker = () => {
        if (props.value) console.log(props.value)
        else filePicker?.current?.click();    
    };
    
    useEffect(() => {
        if (!props.id) setOnlyRead(true);
        else setOnlyRead(false);
    }, [props.id]);

    return (
        <Flex alignItems={'center'} p={'5px 10px'} rowGap={2} bg={props.bg} justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
                {onlyRead ?   
                    <>
                        <Text fontSize={'16px'} color={'var(--color-grayBlack)'}>{props.title}:</Text>
                        <Flex columnGap={2} alignItems={'center'}>
                            <Button 
                                variant={'empty'}  
                                onClick={handlePicker}
                                color={'var(--color-grayBlack)'}
                            >
                                {props.value ? 'Убрать' : 'Добавить договор'}
                            </Button>
                            <Text color={'var(--color-grayBlack)'}>{props.value?.name}</Text> 
                        </Flex>
                        <Input 
                            type={'file'}
                            display={'none'}
                            ref={filePicker}
                            fontSize={'16px'}
                            width={'270px'}
                            variant={'ghost'}
                            accept={props.accept}
                            onChange={props.onChange}
                        />
                    </>
                    :
                    <Flex alignItems={'center'} fontSize={'16px'} color={'var(--color-grayBlack)'} minH={'40px'}>
                        <Text>{props.title}:</Text>
                        <Link 
                            href={hrefDownloadFile} 
                            download={props.value?.name} 
                            width={'260px'} 
                            pl={'10px'}
                        >
                            {props.value?.name}
                        </Link> 
                    </Flex>
                }
            </Flex>
            {props.id && <Button onClick={handleOnlyRead} variant={'empty'}>
                {onlyRead ? 
                    <LockSVG width={'25px'} height={'25px'} color={'var(--color-purple)'}/> 
                    : 
                    <EditSVG width={'25px'} height={'25px'} fill={'var(--color-purple)'}/> 
                }
            </Button>}
        </Flex>
    )
}