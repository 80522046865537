import React from 'react';
import { ReactComponent as LookContactSVG } from '../../../../../../common/icons/lookClient.svg';
import { ReactComponent as DeleteSVG } from '../../../../../../common/icons/trash.svg'; 
import { ReactComponent as PlusSVG } from '../../../../../../common/icons/plus.svg';
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { TypeCompanyContact } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import AddOrUpadateCompanyContact from '../../../../popups/AddOrUpadateCompanyContact';
import DeleteCompanyContactPopup from '../../../../popups/DeleteCompanyContactPopup';
import tableClientsSlice from '../../../../slices/tableClientsSlice';

export default function Contacts() {
  const dispatch = useDispatch();

  const { companyContacts } = useSelector((store: RootState) => store.tableClientsSlice);

  const handleShowAddOrUpadateCompanyContactPopup = (contact?: TypeCompanyContact) => () => {
    if (contact) {
      dispatch(tableClientsSlice.actions.setCompanyContact(contact));
      dispatch(tableClientsSlice.actions.setSelectedCompanyContact(contact));
    }
    dispatch(tableClientsSlice.actions.setShowAddOrUpadateCompanyContactPopup(true));
  } 

  const handleShowDeleteCompanyContactPopup = (contact: TypeCompanyContact) => () => {
    dispatch(tableClientsSlice.actions.setSelectedCompanyContact(contact));
    dispatch(tableClientsSlice.actions.setShowDeleteCompanyContactPopup(true));
  } 
   
  return (
    <Box w={'490px'} h={'225px'} overflowY={'auto'} p={'10px'} bg={'var(--color-grayWhite)'} borderRadius={'25px'} border={'2px solid var(--color-grayBlack)'}>
      <Flex justifyContent={'space-between'}>
        <Text color={'var(--color-grayBlack)'} fontSize={'32px'} mb={'14px'}>Контакты</Text>
        <Tooltip bg={'black'} label={'Добавить контакт'}>
          <Button variant={'empty'} onClick={handleShowAddOrUpadateCompanyContactPopup()}><PlusSVG width={'30px'} height={'30px'}/></Button>
        </Tooltip>
      </Flex>
      <Box> 
        {companyContacts?.map((contact: any, index: number) => {
            const generateKey = contact?.id ? contact.id : index;

            return ( 
              <Flex 
                key={generateKey} 
                h={'61px'} 
                p={'5px 10px'}
                alignItems={'center'}
                color={'var(--color-grayBlack)'}
                bg={index % 2 ? 'var(--color-grayWhite)' : 'var(--color-gray)'} 
                justifyContent={'space-between'}
              >
                <Flex columnGap={2}>
                  <Text fontWeight={'bold'}>{contact?.position}</Text>
                  <Text>{contact?.name}</Text>
                </Flex>
                <Flex>
                  <Tooltip bg={'black'} label={'Посмотреть контакт'}>
                    <Button p={'0px'} variant={'empty'} onClick={handleShowAddOrUpadateCompanyContactPopup(contact)}>
                      <LookContactSVG width={'25px'} height={'25px'} fill={'var(--color-purple)'}/>
                    </Button>
                  </Tooltip>
                  <Tooltip bg={'black'} label={'Удалить контакт'}>
                    <Button p={'0px'} variant={'empty'} onClick={handleShowDeleteCompanyContactPopup(contact)}>
                      <DeleteSVG width={'25px'} height={'25px'} fill={'var(--color-purple)'}/>
                    </Button> 
                  </Tooltip>
                </Flex>
              </Flex>
          )
        })}
      </Box>
      <AddOrUpadateCompanyContact/>
      <DeleteCompanyContactPopup/>
    </Box>
  )
}