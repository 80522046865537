import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditInputOrTextField from '../../../../../../../components/EditInputOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';


export default function IndustryField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleIndustry = (e: ChangeEvent<HTMLInputElement>) => dispatch(tableClientsSlice.actions.setCompany({ industry: e.currentTarget.value }));

    return (
        <EditInputOrTextField 
            bg={props.bg}
            id={company?.id} 
            value={company?.industry} 
            onChange={handleIndustry} 
            title={'Ремесло'}
        />
    )
}
