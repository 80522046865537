import React from 'react'
import RadioField from '../../../components/Form/Fields/RadioField';

const radioListType = [
    { id: 1, value: 'status', label: 'Статус' },
    { id: 2, value: 'task', label: 'Задача' },
];

type Props = {
    register: any,
};

export default function TaskOrStatusTypeField(props: Props) {
    return <RadioField title={'Тип'} register={props.register} radioListValue={radioListType}/>
};