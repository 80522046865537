export const Textarea = {
    variants: {
        'base': (props: any) => {
            return {
                bg: 'transparent', 
                border: '2px solid var(--color-purple)', 
                background: 'var(--color-grayWhite)',
                borderRadius: '25px',
                paddingLeft: '15px',
                fontSize: '14px',
                color: 'var(--color-grayBlack)',
                _focusVisible: {
                    outlineOffset: 0, 
                    outline: 0 
                },
                _placeholder: {
                    color: 'var(--color-grayBlack)'
                },
                _disabled: {
                    bg: 'var(--color-gray)',
                    color: 'var(--color-gray)',
                },
            }
        },
        'ghost': (props: any) => {
            return {
                bg: 'transparent', 
                border: 'none', 
                p: '0 0 0 15px',
                fontSize: '16px',
                color: 'var(--color-grayBlack)',
                _focusVisible: {
                    outlineOffset: 0, 
                    outline: 0 
                },
                _placeholder: {
                    color: 'var(--color-grayBlack)'
                },
                _disabled: {
                    bg: 'var(--color-gray)',
                    color: 'var(--color-gray)',
                },
            }
        },
    }
}