import React from 'react'
import { Button, ButtonGroup, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { OurTeam } from '../../../common/axios';
import { RootState } from '../../../store';
import teamSlice from '../../Team/slices/teamSlice';
import Popup from '../../../components/Popup';

export default function DeleteMemberPopup() {
    const dispatch = useDispatch();
    const { isShowDeleteMemberPopup, selectedMemberId } = useSelector((state: RootState) => state.teamSlice)
 
    const handleClose = () => {
        dispatch(teamSlice.actions.setIsShowDeleteMemberPopup(false));
        dispatch(teamSlice.actions.setSelectedMemberId(null));
    };

    const deleteClient = () => {
        OurTeam.post('/delete', { id: selectedMemberId} )
        .then(({ data }) => {
            const { team } = data;
            dispatch(teamSlice.actions.setTeam(team));
            handleClose();
        })
        .catch(err => console.log(err))
    };
    
    return (
        <Popup
            header={'Удаление участника'}
            isOpen={isShowDeleteMemberPopup}
            border={'2px solid var(--color-purple)'}
            borderRadius={'15px'}
            setOpen={handleClose}
            footer={
                <ButtonGroup>
                    <Button  
                        fontSize={'17px'} 
                        variant={'standard'} 
                        onClick={handleClose}
                    >
                        Отменить
                    </Button>
                    <Button 
                        fontSize={'17px'} 
                        variant={'fill'} 
                        onClick={deleteClient}
                    >
                        Удалить
                    </Button>
                </ButtonGroup>
            }
        >
            <Text color={'var(--color-grayBlack)'}>
                Вы уверены что, хотите удалить этого сотруднкиа?
            </Text>
        </Popup>
    )
}
