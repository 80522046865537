import React from 'react';
import { Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export default function MessageError() {
    const { messageError } = useSelector((state: RootState) => state.loginSlice);

    return <Text color={'red'} h={'17px'}>{messageError}</Text> 
}
