import React from 'react';
import styles from './Feedback.module.css';

export default function Feedback() {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Будем рады  начать наше общение!</p>
      <a className={styles.phone} href='tel:+375293117970'>+37529 311 79 70</a>
      <a className={styles.email} href='mailto:ekaterina@buronomerodin.by'>ekaterina@buronomerodin.by</a>
    </div>
  );
}