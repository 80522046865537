import React from 'react';
import { Flex, Textarea, Text } from '@chakra-ui/react';

type Props = {
  register: any
}

export default function TaskOrStatusTextField(props: Props) {
  return (
    <Flex flexDirection={'column'}>
      <Text color={'var(--color-grayBlack)'} fontWeight={600}>Описание</Text>
      <Textarea {...props.register} variant={'base'}/>
    </Flex>
  )
}
