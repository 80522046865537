import React from 'react';
import { ReactComponent as EmptyStarSvg } from '../../../../../../common/icons/emptyStar.svg';
import { ReactComponent as StarSvg } from '../../../../../../common/icons/star.svg';
import { Company } from '../../../../../../common/axios';
import { Bit } from '../../../../../../common/utils';
import { TypeCompany } from '../../../../types';
import { useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import tableClientsSlice from '../../../../slices/tableClientsSlice';

export type Props = {
  is_important: Bit
}

export default function ImportantCompanyRenderer(row: any) {
  const dispatch = useDispatch();

  const сhangeCompany = (row: TypeCompany, field: string,  value: number | string) => {
    Company.post('/update', { company: row, field, value } )
    .then(({ data }) => {
      const { companies } = data;
      dispatch(tableClientsSlice.actions.setCompanies(companies));
    })
    .catch(err => console.log(err))
  }

  const handleChangeImortantCompany = () => сhangeCompany(row, 'is_important', Number(Boolean(!row?.is_important)));
  
  const isImportantStart: { [key: number]: JSX.Element } = {
    0: <EmptyStarSvg width={'25px'} height={'25px'} fill={'var(--color-purple)'}/>, 
    1: <StarSvg width={'25px'} height={'25px'} fill={'var(--color-purple)'}/> , 
  }

  return (
    <Flex onClick={handleChangeImortantCompany} cursor={'pointer'}>
      {isImportantStart[row['is_important']]}  
    </Flex>
  )
}