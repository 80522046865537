import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { OurTeam } from '../../../../common/axios';
import { RootState } from '../../../../store';
import { columns } from './hot/TableColumns';
import { TypeTeam } from '../../types';
import TableData from '../../../../components/TableData';
import teamSlice from '../../slices/teamSlice';
import DeleteMemberPopup from '../../popups/DeleteMemberPopup';

export default function TableBlock() {
    const dispatch = useDispatch();
    const { team, searchMember, statusesTeamOptions, countryOptions } = useSelector((store: RootState) => store.teamSlice);
 
    const getTeam = () => {
        OurTeam.get('/get_team')
        .then(({ data }) => {
            const { team, options } = data;
        
            dispatch(teamSlice.actions.setTeam(team));
            dispatch(teamSlice.actions.setStatusesTeamOptions(options.statusesTeamOptions));
            dispatch(teamSlice.actions.setCountryOptions(options.countriesOptions));
        })
        .catch(err => console.log(err))
    };

    const rows = team?.map((teamMember: TypeTeam) => {
        const { id, name, country, speciality, status } = teamMember;        

        if (!countryOptions || !statusesTeamOptions) return;

        const countryOption = (countryOptions as any)[country as any];
        const statusOption = (statusesTeamOptions as any)[status as any];
       
        return ({ 
            id, 
            name, 
            country: countryOption, 
            speciality, 
            status: statusOption,
            actions: 1,
        });
    });

    const foundRows = () => {
        const search = searchMember.toLowerCase().trim();

        return search
        ? rows.filter((n: any) => Object.values(n).some(m => String(m).toLowerCase().includes(search)))
        : rows;
    };

    useEffect(() => getTeam(), []);

    return (
        <>
            <TableData columns={columns} rows={foundRows} />
            <DeleteMemberPopup />
        </> 
    )
}
