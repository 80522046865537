import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';
import FileField from '../../../../../../../components/FileField';

export default function AgreementField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleAgreement = (e: ChangeEvent<HTMLInputElement>) => dispatch(tableClientsSlice.actions.setCompany({ agreement: e.currentTarget.files![0] ?? '' }));  
   
    return (
        <FileField
            bg={props.bg}
            id={company?.id} 
            title={'Договор'}
            accept={'.pdf,.doc'}
            value={company?.agreement} 
            onChange={handleAgreement} 
        />
    )
}