import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import PasswordField from './components/PasswordField';
import MessageError from './components/MessageError';
import LoginButton from './components/LoginButton';
import LoginField from './components/LoginField';

export default function Login() {
    return (
        <Flex 
            w={'100%'}
            alignItems={'center'}
            flexDirection={'column'} 
            justifyContent={'center'} 
        >
            <Text 
                mb={'70px'} 
                mt={'100px'}
                fontSize={'52px'} 
                color={'purple'}
                fontFamily={'var(--fontFamilyPac)'}
                mr={'370px'}
            >
                #приветствую вас!
            </Text>
            <Flex 
                p={'25px'}
                w={'523px'}
                h={'355px'}
                bg={'var(--color-grayWhite)'}
                borderRadius={'30px'}
                alignItems={'center'}
                flexDirection={'column'} 
                border={'4px solid var(--color-grayBlack)'}
            >
                <Flex flexDirection={'column'} gap={'7px'} mt={'15px'}>
                    <MessageError/>
                    <LoginField/>
                    <PasswordField/>
                    <LoginButton/>
                </Flex>
            </Flex>
        </Flex>
    );
}