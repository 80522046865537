import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import styles from './RadioField.module.scss';

type Props = {
    title: string,
    register: any,
    radioListValue:  {
        id: number,
        value: number|string,
        label:string,
    }[],
}

export default function RadioField(props: Props) {
  return (
    <Flex flexDirection={'column'} style={styles}>
        <Text color={'var(--color-grayBlack)'} fontWeight={600}>{props.title}</Text>
        {props.radioListValue?.map((radioItem: any) => {
            return ( 
                <label htmlFor={radioItem.value} key={radioItem.value} style={styles}>
                <input 
                    type='radio' 
                    {...props.register}
                    value={radioItem.value}
                    id={radioItem.value}
                />
                <Text color={'var(--color-grayBlack)'}>{radioItem.label}</Text>
            </label>
            )
        } )}
    </Flex>
  )
}
