import React from 'react';
import s from './MostAdvanced.module.css';

export default function MostAdvanced() {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <p className={s.text}>
          Мы постоянно следим за последними тенденциями и <br/> 
          инновациями в мире дизайна и разработки, чтобы 
          предлагать самые передовые решения
        </p>
        <div className={s.blockImg}>
          <div className={s.img} />
        </div>
      </div>
    </div>
  )
}