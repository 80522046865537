import React from 'react'
import { ReactComponent as LookToClient } from '../../../../../../common/icons/lookClient.svg';
import { ReactComponent as DeleteSvg } from '../../../../../../common/icons/trash.svg';
import { Flex, Tooltip } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import teamSlice from '../../../../slices/teamSlice';


export default function ActionsTeamRenderer(row: any) {
    const dispatch = useDispatch();
    const LINK_NAME = process.env.REACT_APP_LINK_NAME;
 
    const handleDeleteCompany = () => {
        dispatch(teamSlice.actions.setIsShowDeleteMemberPopup(true));
        dispatch(teamSlice.actions.setSelectedMemberId(row.id));
    }

    const listActios = [
        { id: 1, icon: <NavLink to={`/${LINK_NAME}/team/${row.id}`}><LookToClient width={'25px'} height={'25px'} fill={'var(--color-purple)'}/></NavLink>, messageTooltip: 'Посмотреть или редактировать участника', },
        { id: 2, icon: <DeleteSvg width={'25px'} height={'25px'} fill={'var(--color-purple)'}/>, messageTooltip: 'Удалить участника', action: handleDeleteCompany},
    ];

    return (
        <Flex columnGap={2}>
            {listActios.map((listAction: any) => (
                <Tooltip key={listAction.id} bg='black' label={listAction.messageTooltip} fontSize='md'>
                    <Flex onClick={listAction?.action} cursor={'pointer'}>
                        {listAction.icon}
                    </Flex>
                </Tooltip>
            ))}
        </Flex>
    )
}