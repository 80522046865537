import React, { useEffect } from 'react';
import { setLogged } from '../../pages/Login/slice/loginSlice';
import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { RootState } from '../../store';

export default function RequireAuth({ children }: any) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { logged } = useSelector((store: RootState) => store.loginSlice);
    const [ cookies ] = useCookies([process.env.REACT_APP_COOKIE_LOGGED!]);

    useEffect(() => {
        if (logged) dispatch(setLogged(logged));
    }, []);

    if (!cookies._LCAR && !logged) {     
        return <Navigate to='/' state={{ from: location }}/>
    }

    return children;
}