import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditTextareaOrTextField from '../../../../../../../components/EditTextareaOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function WithWorkField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleWithWork = (e: ChangeEvent<HTMLTextAreaElement>) => dispatch(tableClientsSlice.actions.setCompany({ with_work: e.currentTarget.value }));

  return (
    <EditTextareaOrTextField
      bg={props.bg}
      id={company?.id}
      title={'С кем работают'}
      value={company?.with_work}
      onChange={handleWithWork}
    />
  )
}
