import React from 'react';
import { Company } from '../../../../../../common/axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useCookies } from 'react-cookie';
import { Button } from '@chakra-ui/react';
import tableClientsSlice from '../../../../slices/tableClientsSlice';
import toast from 'react-hot-toast';

export default function SaveClient() {
  const dispatch = useDispatch();
  const { company, companyContacts, companyTasksOrStatuses } = useSelector((state: RootState) => state.tableClientsSlice);
  const [_cookie] = useCookies([process.env.REACT_APP_COOKIE_USER!]);
  
  const save = () => {  
    const formData = new FormData();    

    const data = {
      company: { ...company, agreement: company?.agreement?.name },
      createUserId: _cookie?._UCAR?.id ?? 0,
      companyContacts: companyContacts ?? [],
      companyTasksOrStatuses: companyTasksOrStatuses ?? [],
    };

    formData.append('companyAgreement', company?.agreement ?? '');
    formData.append('data', JSON.stringify(data as any));

    Company.post('/save', formData)
    .then(({ data }) => {
      const { message, companies, savedCompanyContacts, savedCompanyTasksOrStatuses } = data;
       
      dispatch(tableClientsSlice.actions.setCompanies(companies));
      dispatch(tableClientsSlice.actions.setCompanyContacts(savedCompanyContacts)); 
      dispatch(tableClientsSlice.actions.setCompanyTasksOrStatuses(savedCompanyTasksOrStatuses));
      toast.success(message);
    })
    .catch(err => toast.error(err.response.data))
  }

  return (
    <Button 
      variant={'standard'}
      borderColor={'var(--color-purple)'}
      onClick={save}
      fontSize={'16px'}
      fontWeight={'600'}
    >
      Сохранить
    </Button>
  )
}