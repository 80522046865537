import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { propsField } from '..';
import { RootState } from '../../../../../../../store';
import EditTextareaOrTextField from '../../../../../../../components/EditTextareaOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function DescriptionField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleDescription = (e: ChangeEvent<HTMLTextAreaElement>) => dispatch(tableClientsSlice.actions.setCompany({ description: e.currentTarget.value }));
 
    return (
        <EditTextareaOrTextField
            bg={props.bg}
            id={company?.id}
            title={'Комментарии'}
            value={company?.description}
            onChange={handleDescription}
        />
    )
}
