import React, { ChangeEvent } from 'react';
import { Text, Input } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setName } from '../../slice/loginSlice';

export default function LoginField() {
  const dispatch = useDispatch();
  const { name: login } = useSelector((store: RootState) => store.loginSlice);
  const handleName = (e: ChangeEvent) => dispatch(setName((e.currentTarget as HTMLTextAreaElement).value));  

  return (
    <>
      <Text mb={'3px'} fontSize={'28px'} color={'var(--color-grayBlack)'}>
        Ваше имя
      </Text>
      <Input 
        w={'365px'} 
        variant={'lineBottom'}
        value={login}
        onChange={handleName}
      />
    </>
  );
}
