import { AuthUser } from "../common/axios";
import config from "../common/them/config";

const isMemoryJWTService = () => {
    let isMemoryJWT: any = null;
    let refreshTimeotId: any = null;

    const refreshToken = (expiration: number) => {
        const timeoutTrigger = expiration - 10000;

        refreshTimeotId = setTimeout(() => {
            AuthUser.post('/refresh')
            .then((({ data }) => {
                const { accessToken, accessTokenExpiration } = data;
                setToken(accessToken, accessTokenExpiration);
            }))
            .catch(err => console.log(err))
        }, timeoutTrigger)
    }

    const abortRefreshToken = () => {
        if (refreshTimeotId) {
            clearInterval(refreshTimeotId)
        }
    }

    const setToken = (token: string, tokenExpiration: number) => {
        isMemoryJWT = token;
        refreshToken(tokenExpiration);
        localStorage.setItem(config.LOGOUT_STORAGE_KEY, String(Date.now()));
    };

    const getToken = () => isMemoryJWT;

    const deleteToken = () => {
        isMemoryJWT = null;
        abortRefreshToken();
    }

    return { getToken, setToken,  deleteToken };
}

export default isMemoryJWTService();