import React, { ChangeEvent } from 'react';
import { Text, Input } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setPassword } from '../../slice/loginSlice';

export default function PasswordField() {
  const dispatch = useDispatch();
  const { password } = useSelector((store: RootState) => store.loginSlice);
  const handlePassword = (e: ChangeEvent) => dispatch(setPassword((e.currentTarget as HTMLTextAreaElement).value));  

  return (
    <>
      <Text mb={'3px'} fontSize={'28px'} color={'var(--color-grayBlack)'}>
        Ваш пароль
      </Text>
      <Input 
        w={'365px'} 
        variant={'lineBottom'}
        type='password'
        value={password}
        onChange={handlePassword}
      />
    </>
  );
}
