import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Company } from '../../../../common/axios'
import { RootState } from '../../../../store';
import { columns } from './hot/TableColumns';
import { TypeCompany } from '../../types';
import DeleteClientsPopup from '../../popups/DeleteClientPopup';
import tableClientsSlice from '../../slices/tableClientsSlice';
import TableData from '../../../../components/TableData';

export default function TableBlock() {
    const dispatch = useDispatch();
    const { searchClients } = useSelector((store: RootState) => store.pageClientsSlice);
    const { companies, countryOptions, statusOptions } = useSelector((store: RootState) => store.tableClientsSlice);

    const getCompanies = () => {
        Company.get('/get_companies')
        .then(({ data }) => {
            const { companies, options } = data;
            dispatch(tableClientsSlice.actions.setCompanies(companies));
            dispatch(tableClientsSlice.actions.setCountryOptions(options.countriesOptions));
            dispatch(tableClientsSlice.actions.setStatusOptions(options.statusesClientsOptions));
        })
        .catch(err => console.log(err))
    }

    const rows = companies?.map((company: TypeCompany) => {
        const { id, name, country, industry, status, is_important } = company;        

        if (!countryOptions || !statusOptions) return;

        const countryOption = (countryOptions as any)[country as any];
        const statusOption = (statusOptions as any)[status as any];
       
        return ({ 
            id, 
            is_important, 
            company: name, 
            country: countryOption, 
            industry, 
            status: statusOption,
            actions: 1,
        });
    });

    const foundRows = () => {
        const search = searchClients.toLowerCase().trim();

        return search
        ? rows.filter((row: any) => Object.values(row).some(r => String(r).toLowerCase().includes(search)))
        : rows;
    }    

    useEffect(() => getCompanies(), []);
    
    return (
        <>
            <TableData columns={columns} rows={foundRows} />
            <DeleteClientsPopup />
        </>
    ) 
}
