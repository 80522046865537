import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import DescriptionField from './fields/DescriptionField';
import AgreementField from './fields/AgreementField';
import IndustryField from './fields/IndustryField';
import WithWorkField from './fields/WithWorkField';
import CountryField from './fields/CountryField';
import AddressField from './fields/AddressField';
import WebsiteField from './fields/WebsiteField';
import SocialField from './fields/SocialField';
import NameField from './fields/NameField';

export type propsField = {
  bg: string
}

export default function CardClient() {
  return (
    <Box w={'490px'} h={'500px'} overflowY={'auto'} p={'10px'} bg={'var(--color-grayWhite)'} borderRadius={'25px'} border={'2px solid var(--color-grayBlack)'}>
      <Text color={'var(--color-grayBlack)'} fontSize={'32px'} mb={'14px'}>Карточка клиента</Text>
      <Flex flexDirection={'column'}>
        <NameField bg={'var(--color-gray)'}/>
        <CountryField bg={'var(--color-grayWhite)'}/>
        <IndustryField bg={'var(--color-gray)'}/>
        <WebsiteField bg={'var(--color-grayWhite)'}/>
        <SocialField bg={'var(--color-gray)'}/>
        <AgreementField bg={'var(--color-grayWhite)'}/>
        <WithWorkField bg={'var(--color-gray)'}/>
        <AddressField bg={'var(--color-grayWhite)'}/>
        <DescriptionField bg={'var(--color-gray)'}/>
      </Flex>
    </Box>              
  )
}
