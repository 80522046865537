import { extendTheme } from '@chakra-ui/react'
import colors from './foundations/colors';
import * as components from './components';
import '@fontsource/pacifico/400.css';
import '@fontsource/roboto/400.css';

const overrides = {
    colors,
    components: { ...components },
}

export default extendTheme(overrides);