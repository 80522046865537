import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Company } from '../../../common/entities/company';

const name = 'companyClientsSlice' as 'companyClientsSlice';

export type InitialCompanyClientsSlice = Company;

// @ts-ignore
const initialState: InitialCompanyClientsSlice = {
    id: 1,
    name: '',
    country: { value: '', label: '' },
    address: '',
    industry: '',
    description: '',
    website: '',
    social: '',
    // agreement: undefined,
    with_work: '',
    contact_LPR: '',
    contact_main: '',
    contacts: '',
    status: { value: '', label: '' },
    is_important: 0
}

const tableClientsSlice = createSlice({
    name,
    initialState,
    reducers: {  
        setId: (state, action: PayloadAction<InitialCompanyClientsSlice['id']>) => { 
            state.id = action.payload 
        },
        setName: (state, action: PayloadAction<InitialCompanyClientsSlice['name']>) => { 
            state.name = action.payload 
        },
        setCountry: (state, action: PayloadAction<InitialCompanyClientsSlice['country']>) => { 
            state.country = action.payload 
        },
        setAddress: (state, action: PayloadAction<InitialCompanyClientsSlice['address']>) => {
            state.address = action.payload;
        },
        setIndustry: (state, action: PayloadAction<InitialCompanyClientsSlice['industry']>) => {
            state.industry = action.payload;
        },
        setDescription: (state, action: PayloadAction<InitialCompanyClientsSlice['description']>) => {
            state.description = action.payload;
        },
        setWebsite: (state, action: PayloadAction<InitialCompanyClientsSlice['website']>) => {
            state.website = action.payload;
        },
        setSocial: (state, action: PayloadAction<InitialCompanyClientsSlice['social']>) => {
            state.social = action.payload;
        },
        setAgreement: (state, action: PayloadAction<InitialCompanyClientsSlice['agreement']>) => {
            state.agreement = action.payload;
        },
        setWithWork: (state, action: PayloadAction<InitialCompanyClientsSlice['with_work']>) => {
            state.with_work = action.payload;
        },
        setContacts: (state, action: PayloadAction<InitialCompanyClientsSlice['contacts']>) => {
            state.contacts = action.payload;
        },
        setStatus: (state, action: PayloadAction<InitialCompanyClientsSlice['status']>) => {
            state.status = action.payload;
        },
    }
});

const getState = (state: any) => state[name] as InitialCompanyClientsSlice;

export default {
    ...tableClientsSlice,
    selectors: {
        getState,
    }
}

