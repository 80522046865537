import React from 'react';
import iconLogo from '../../../../assets/icons/LogoPopup1440.svg';
import s from './Logo.module.css';

export default function Logo() {
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <img src={iconLogo} alt='logo' />
      </div>
    </div>
  )
}
