import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditInputOrTextField from '../../../../../../../components/EditInputOrTextField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function NameField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleName = (e: ChangeEvent<HTMLInputElement>) => dispatch(tableClientsSlice.actions.setCompany({ name: e.currentTarget.value }));

    return (
        <EditInputOrTextField 
            bg={props.bg}
            id={company?.id} 
            value={company?.name} 
            onChange={handleName} 
            title={'Название'}
        />
    )
}
