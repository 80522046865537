import React from 'react';
import gifAvatar from '../../assets/gifs/Avatar.gif';
import gifHeart from '../../assets/gifs/Heart.gif';
import gifZoom from '../../assets/gifs/Zoom.gif';
import s from './OurPhilosphy.module.css';

type TypeListGif = {
    id: number,
    gif: string,
    text: string,
};

const listGifs: TypeListGif[] = [
    { id: 1, gif: gifZoom, text: 'Ценим индивидуальный подход к каждому проекту' },
    { id: 2, gif: gifHeart, text: 'Прибавляем человечности вашему бизнесу' },
    { id: 3, gif: gifAvatar, text: 'Помогаем строить гармоничные отношения с клиентами' },
];

export default function OurPhilosphy() {
    return (
    <div className={s.wrapper}>
        <div className={s.container}>
            <div className={s.title}>
                <p className={s.titleOne}>НАША </p> 
                <p className={s.titleTwo}>ФИЛОСОФИЯ</p>
            </div>
            <ul className={s.listGifs}>
                {listGifs.map((itemGif: TypeListGif) => {
                    return (
                        <li key={itemGif.id} className={s.itemGif}>
                            <div className={s.itemGifCircle}>
                                <img  src={itemGif.gif} alt='gif'/>
                            </div>
                            <p>{itemGif.text}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    </div>
    )
}