import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Flex, Text } from '@chakra-ui/react';
import DatePickerCustomField from '../../../../../components/DatePickerCustomField';
import tableClientsSlice from '../../../slices/tableClientsSlice';

export default function BirthdayField() {
    const dispatch = useDispatch();
    const { companyContact } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleBirthday = (e: Date) => dispatch(tableClientsSlice.actions.setCompanyContact({ birthday: e }));
    
    return (
        <Flex flexDirection={'column'} w={'280px'}>
            <Text color={'var(--color-grayBlack)'} fontWeight={'bold'}>День рождения:</Text>
            <DatePickerCustomField 
                value={companyContact?.birthday ?? new Date()} 
                onChange={handleBirthday}
            />
        </Flex>
    )
}
