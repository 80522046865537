import React, {ChangeEvent, useEffect, useState } from 'react'
import { ReactComponent as EditSVG } from '../../common/icons/edit.svg';
import { ReactComponent as LockSVG } from '../../common/icons/lock.svg';
import { Flex, Text, Button, Textarea } from '@chakra-ui/react';

type Props = {
    bg: string,
    id: number | undefined,
    title: string,
    value: string | undefined,
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void,
}

export default function EditTextareaOrTextField(props: Props) {
    const [onlyRead, setOnlyRead] = useState(false);
    const handleOnlyRead = () => setOnlyRead(prev => !prev);

    useEffect(() => {
        if (!props.id) setOnlyRead(true)
        else setOnlyRead(false)
    }, [props.id]);

    return (
        <Flex alignItems={'center'} p={'5px 10px'} rowGap={2} bg={props.bg} justifyContent={'space-between'}>
            <Flex>
                {onlyRead ?   
                    <>
                        <Text fontSize={'16px'} color={'var(--color-grayBlack)'}>{props.title}:</Text>
                        <Textarea 
                            width={'260px'}
                            variant={'ghost'}
                            value={props.value ?? ''}
                            onChange={props.onChange}
                        />
                    </>
                    :
                    <Flex alignItems={'center'} fontSize={'16px'} color={'var(--color-grayBlack)'} minH={'80px'}>
                        <Text>{props.title}:</Text>
                        <Text width={'260px'} pl={'10px'}>{props.value}</Text> 
                    </Flex>
                }
            </Flex>
            {props.id && <Button onClick={handleOnlyRead} variant={'empty'}>
                {onlyRead ? 
                    <LockSVG width={'25px'} height={'25px'} color={'var(--color-purple)'}/> 
                    : 
                    <EditSVG width={'25px'} height={'25px'} fill={'var(--color-purple)'}/> 
                }
            </Button>}
        </Flex>
    )
}
