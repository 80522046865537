import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const name = 'pageClientsSlice' as 'pageClientsSlice';

export type InitialPageClientsSlice = {
    searchClients: string,
}
  
const initialState: InitialPageClientsSlice = {
    searchClients: '',
}

const pageClientsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSearchCompany: (state, action: PayloadAction<InitialPageClientsSlice['searchClients']>) => {
            state.searchClients = action.payload;
        },  
    },
});

export const { setSearchCompany } = pageClientsSlice.actions;
export default pageClientsSlice.reducer;