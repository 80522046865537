import React from 'react';
import { Button, ButtonGroup, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { Company } from '../../../common/axios';
import { RootState } from '../../../store';
import tableClientsSlice from '../slices/tableClientsSlice';
import Popup from '../../../components/Popup';

export default function DeleteClientPopup() {
    const dispatch = useDispatch();
    const { isShowDeleteCompanyPopup, selectedCompanyId } = useSelector((state: RootState) => state.tableClientsSlice)
 
    const handleClose = () => {
        dispatch(tableClientsSlice.actions.setShowDeleteCompanyPopup(false));
        dispatch(tableClientsSlice.actions.setSelectedCompanyId(null));
    };

    const deleteClient = () => {
        Company.post('/delete', { id: selectedCompanyId} )
        .then(({ data }) => {
            const { companies } = data;
            dispatch(tableClientsSlice.actions.setCompanies(companies));
            handleClose();
        })
        .catch(err => console.log(err))
    };
    
    return (
        <Popup
            header={'Удаление компании'}
            border={'2px solid var(--color-purple)'}
            borderRadius={'15px'}
            isOpen={isShowDeleteCompanyPopup}
            setOpen={handleClose}
            footer={
                <ButtonGroup>
                    <Button  
                        fontSize={'17px'} 
                        variant={'standard'} 
                        onClick={handleClose}
                    >
                        Отменить
                    </Button>
                    <Button 
                        fontSize={'17px'} 
                        variant={'fill'} 
                        onClick={deleteClient}
                    >
                        Удалить
                    </Button>
                </ButtonGroup>
            }
        >
            <Text color={'var(--color-grayBlack)'}>
                Вы уверены что, хотите удалить эту компанию?
            </Text>
        </Popup>
    )
}