import React from 'react';
import { PropsContactPopup } from '../..';
import { motion } from 'framer-motion';
import { Element } from 'react-scroll';
import iconArrow from '../../assets/icons/Arrow.svg';
import s from './WhoAreWe.module.css';

export default function WhoAreWe(props: PropsContactPopup) {
    const textAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: (custom: number) => ({
            y: 0,
            opacity: 1,
            transition: {  duration: .7, delay: custom / 12 }
        })
    };

    const text = "Мы — команда творческих профессионалов, страстно увлеченных созданием уникальных цифровых продуктов. Наша миссия заключается в том, чтобы помочь вашему бизнесу процветать в цифровой эпохе, предлагая качественные и инновационные решения в области разработки и дизайна".split(" ");
   
    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <div className={s.blockTeam} >
                    <div className={s.blockButton}>
                        <button onClick={props.showContactPopup} className={s.button}>
                            <img src={iconArrow} alt='arrow'/>
                            <p>Начнем?</p>
                        </button>
                    </div>
                    <div className={s.blockOurTeam}/>
                </div>
                <Element name="aboutUs">
                    <div/>
                </Element>
                <div className={s.content} >
                    <h3 className={s.title}>Кто мы?</h3>
                    {text.map((el, i) => (
                        <motion.span
                            key={i}
                            custom={i}
                            variants={textAnimation}
                            initial="hidden"
                            whileInView="visible"
                            className={s.text}
                        >
                        {el}{" "}
                        </motion.span>
                    ))}
                </div>
            </div>
        </div>
    )
}