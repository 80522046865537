import React, { ChangeEvent } from 'react'
import { Flex, Image, Input } from '@chakra-ui/react';
import searchSvg from '../../common/icons/search.svg';

type Props = {
  value: string;
  setValue: (e: ChangeEvent<HTMLInputElement>) => void,
}

export default function SearchData(props: Props) {
  return (
    <Flex 
      w={'800px'} 
      h={'66px'} 
      p={'0 10px'}
      alignItems={'center'} 
      borderRadius={'25px'} 
      background={'grayWhite'} 
      border={'2px solid var(--color-grayBlack)'} 
    >
      <Image src={searchSvg}/>
      <Input 
        border={'none'}
        fontSize={'18px'}
        value={props.value}
        onChange={props.setValue}
        _focusVisible={{ outlineOffset: 0, outline: 0  }}
      />
    </Flex>
  )
}
