import React from 'react';
import RadioField from '../../../components/Form/Fields/RadioField';

const radioListActive = [
  { id: 2, value: 0, label: 'Нет' },
  { id: 1, value: 1, label: 'Да' },
];

type Props = {
  register: any,
};



export default function TaskOrStatusActiveField(props: Props) {
  return <RadioField title={'Показать'} register={props.register} radioListValue={radioListActive}/>
};
