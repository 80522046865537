import React, { ChangeEvent } from 'react';
import { ReactComponent as AddClientSvg } from '../../../../common/icons/addClient.svg';
import { setSearchCompany } from '../../slices/pageClientsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { NavLink } from 'react-router-dom';
import { Flex, Tooltip } from '@chakra-ui/react';
import SearchData from '../../../../components/SearchData';

export default function TopBlock() {
  const dispatch = useDispatch();
  const LINK_NAME = process.env.REACT_APP_LINK_NAME;
  const { searchClients } = useSelector((store: RootState) => store.pageClientsSlice);
  
  const handleSearch = (e: ChangeEvent) => dispatch(setSearchCompany((e.currentTarget as HTMLTextAreaElement).value)); 

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex cursor={'pointer'}>
        <NavLink to={`/${LINK_NAME}/clients/new-company`}>
          <Tooltip bg={'black'} label={'Добавить клиента'}>
            <AddClientSvg 
              width={'55px'} 
              height={'55px'} 
              color={'var(--color-purple)'}
            />
          </Tooltip>
        </NavLink>
      </Flex>
      <SearchData value={searchClients} setValue={handleSearch}/>
    </Flex>
  )
}