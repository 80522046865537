import React, { useState } from 'react';
import { Company } from '../../../../../../common/axios';
import { useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import tableClientsSlice from '../../../../slices/tableClientsSlice';

export default function ImportantAction(title: string) {  
  const dispatch = useDispatch();
  const [value, setValue] = useState<number>(0);
  const [changeTitle, setChangeTitle] = useState(title);

  const getCompanies = () => {
    Company.get('/get_companies')
    .then(({ data }) => {
      // dispatch(tableClientsSlice.actions.setCompanies(data.companies))
      // setChangeTitle('Все');
      // setValue(0);
    })
    .catch(err => console.log(err))
  }

  // TODO вынести функцию
  const getSortCompanies = () => {
    Company.post('/get_sort_companies', { sort: 'is_important', value: 1 })
    .then(({ data }) => {
      // dispatch(tableClientsSlice.actions.setCompanies(data.sortedCompanies));
      // if (value === 0) setChangeTitle('Не важные');
      // else if (value === 1) setChangeTitle('Важные');
    })
    .catch(err => console.log(err))
  }

  const handleIsImportant = () => {
    // setValue(prev => prev + 1);

    // if (value >= 2) getCompanies();
    // else getSortCompanies();
  }

  return <Flex cursor={'pointer'} onClick={handleIsImportant}>{'changeTitle'}</Flex>
}