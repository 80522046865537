import React, { ChangeEvent } from 'react';
import { ReactComponent as AddMemberSvg } from '../../../../common/icons/addMember.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Tooltip } from '@chakra-ui/react';
import { RootState } from '../../../../store';
import { NavLink } from 'react-router-dom';
import SearchData from '../../../../components/SearchData';
import teamSlice from '../../slices/teamSlice';

export default function TopBlock() {
    const dispatch = useDispatch();
    const LINK_NAME = process.env.REACT_APP_LINK_NAME;
    const { searchMember } = useSelector((store: RootState) => store.teamSlice);

    const handleSearch = (e: ChangeEvent) => dispatch(teamSlice.actions.setSearchMember((e.currentTarget as HTMLTextAreaElement).value)); 

    return (
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Flex cursor={'pointer'}>
            <NavLink to={`/${LINK_NAME}/team/new-member`}>
                <Tooltip bg={'black'} label={'Добавить участника'}>
                <AddMemberSvg 
                    width={'55px'} 
                    height={'55px'} 
                    color={'var(--color-purple)'}
                />
                </Tooltip>
            </NavLink>
            </Flex>
            <SearchData value={searchMember} setValue={handleSearch}/>
        </Flex>
    )
}
