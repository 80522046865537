import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const name = 'loginSlice' as 'loginSlice';

export type InitialLoginState = {
  name: string,
  password: string,
  logged: boolean,
  appReady: boolean, 
  messageError: string,
}

const initialState: InitialLoginState = {
  name: '',
  password: '',
  logged: false,
  appReady: false,
  messageError: '',
}

const loginSlice = createSlice({
  name,
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<InitialLoginState['name']>) => {
      state.name = action.payload
    }, 
    setPassword: (state, action: PayloadAction<InitialLoginState['password']>) => {
      state.password = action.payload
    }, 
    setLogged: (state, action: PayloadAction<InitialLoginState['logged']>) => {
      state.logged = action.payload
    }, 
    setAppReady: (state, action: PayloadAction<InitialLoginState['appReady']>) => {
      state.appReady = action.payload
    }, 
    setMessageError: (state, action: PayloadAction<InitialLoginState['messageError']>) => {
      state.messageError = action.payload
    },
  },
});

export const { setName, setPassword, setLogged, setAppReady, setMessageError } = loginSlice.actions;
export default loginSlice.reducer;