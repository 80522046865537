import React from 'react';
import { countriesOption } from '../../../../../common/options/countryOption';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from '../../../../../common/utils';
import { RootState } from '../../../../../store';
import { Flex, Text } from '@chakra-ui/react';
import tableClientsSlice from '../../../slices/tableClientsSlice';
import BaseSelect from '../../../../../components/BaseSelect';

export default function CountryField() {
    const dispatch = useDispatch();
    const { companyContact } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleCountry = (e: Option|any) => dispatch(tableClientsSlice.actions.setCompanyContact({ country: e?.value }));
    const selectCountry = countriesOption.filter((o: Option) => (o.value === companyContact?.country as any))[0];
  
    return (
        <Flex  w={'280px'} flexDirection={'column'}>
            <Text fontWeight={'bold'}  color={'var(--color-grayBlack)'}>Выберите страну:</Text>
            <BaseSelect 
                width={'280px'}
                menuWidth={'280px'}
                background={'transparent'}
                border={'none'}
                borderHover={'none'}
                placeholder={'Выберите страну'}
                borderRadius={'0'}
                borderBottom={'2px solid var(--color-grayBlack)'}
                borderHoverBottom={'2px solid var(--color-grayBlack)'}
                munuBackground={'var(--color-gray)'}
                options={countriesOption}
                value={selectCountry}
                onChange={handleCountry}
            />
        </Flex>
    );
}
