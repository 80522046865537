import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
    defaultProps: {
        colorScheme:'blue'
    },
    baseStyle: {
        borderRadius: '8px',
        fontWeight: 'normal',
    },
    variants: {
        'standard': (props: any) => {
            return {
                border: '2px solid var(--color-grayBlack)',
                borderRadius: '32px',
                bg: 'transparent',
                fontSize: '25px',
                color: 'var(--color-grayBlack)',
                transition: '.4s opacity',
                _hover: {
                   bg: 'transparent',
                   opacity: .7
                },

                _disabled: {
                    _hover: {
                        transform: 'unset'
                    }
                }
            }
        },
        'fill': (props: any) => {
            return {
                border: '2px solid var(--color-grayBlack)',
                borderRadius: '32px',
                bg: 'var(--color-grayBlack)',
                fontSize: '25px',
                color: 'var(--color-grayWhite)',
                transition: '.4s opacity',
                _hover: {
                   bg: 'var(--color-grayBlack)',
                   opacity: .7
                },

                _disabled: {
                    _hover: {
                        transform: 'unset'
                    }
                }
            }
        },
        'empty': (props: any) => {
            return {
                border: 'none',
                bg: 'transparent',
                fontSize: '16px',
                transition: '.4s opacity',
                _hover: {
                    bg: 'transparent',
                    opacity: .7
                },

                _disabled: {
                    _hover: {
                        transform: 'unset'
                    }
                }
            }
        },
    },
};