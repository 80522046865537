import React from 'react';
import { Flex } from '@chakra-ui/react';
import TableBlock from './components/TableBlock';
import TopBlock from './components/TopBlock';

export default function Team() {
  return (
    <Flex flexDirection={'column'} rowGap={4}>
      <TopBlock/>
      <TableBlock/>
    </Flex>
  )
}
