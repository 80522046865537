import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TypeCompany, TypeCompanyContact, TypeCompanyTasksOrStatus } from '../types';
import { Option } from '../../../common/utils';

const name = 'tableClientsSlice' as 'tableClientsSlice';

export type InitialTableClientsSlice = {
    company: TypeCompany|null,
    companies: TypeCompany[],
    companyContact: TypeCompanyContact|null,
    companyContacts: TypeCompanyContact[],
    companyTaskOrStatus: TypeCompanyTasksOrStatus|null,
    companyTasksOrStatuses: TypeCompanyTasksOrStatus[],
    countryOptions: Option|null,
    statusOptions: Option|null,
    selectedCompanyId: number|null,
    isShowAddOrUpadateCompanyContact: boolean,
    isShowDeleteCompanyPopup: boolean,
    isShowDeleteCompanyContacntPopup: boolean,
    selectedCompanyContact: TypeCompanyContact|null,
  }
  
const initialState: InitialTableClientsSlice = {
    company: {},  
    companies: [],
    companyContact: {},
    companyContacts: [],
    companyTaskOrStatus: {},
    companyTasksOrStatuses: [],
    countryOptions: null,
    statusOptions: null,
    selectedCompanyId: null,
    isShowAddOrUpadateCompanyContact: false,
    isShowDeleteCompanyPopup: false,
    isShowDeleteCompanyContacntPopup: false,
    selectedCompanyContact: null
}

const tableClientsSlice = createSlice({
    name,
    initialState,
    reducers: {  
        setCompany: (state, action: PayloadAction<InitialTableClientsSlice['company']>) => { 
            state.company = { ...state.company, ...action.payload } 
        },
        setCompanies: (state, action: PayloadAction<InitialTableClientsSlice['companies']>) => {
            state.companies = action.payload;
        }, 
        setCompanyContact: (state, action: PayloadAction<InitialTableClientsSlice['companyContact']>) => { 
            state.companyContact = { ...state.companyContact, ...action.payload } 
        },
        setCompanyContacts: (state, action: PayloadAction<InitialTableClientsSlice['companyContacts']>) => {
            state.companyContacts = action.payload;
        },
        setCompanyTaskOrStatus: (state, action: PayloadAction<InitialTableClientsSlice['companyTaskOrStatus']>) => { 
            state.companyTaskOrStatus = { ...state.companyTaskOrStatus, ...action.payload } 
        },
        setCompanyTasksOrStatuses: (state, action: PayloadAction<InitialTableClientsSlice['companyTasksOrStatuses']>) => {
            state.companyTasksOrStatuses = action.payload;
        },
        setCountryOptions: (state, action: PayloadAction<InitialTableClientsSlice['countryOptions']>) => {
            state.countryOptions = action.payload;
        },
        setStatusOptions: (state, action: PayloadAction<InitialTableClientsSlice['statusOptions']>) => {
            state.statusOptions = action.payload;
        },
        setSelectedCompanyId: (state, action: PayloadAction<InitialTableClientsSlice['selectedCompanyId']>) => { 
            state.selectedCompanyId = action.payload;
        },
        setShowAddOrUpadateCompanyContactPopup: (state, action: PayloadAction<InitialTableClientsSlice['isShowAddOrUpadateCompanyContact']>) => { 
            state.isShowAddOrUpadateCompanyContact = action.payload;
        },
        setShowDeleteCompanyPopup: (state, action: PayloadAction<InitialTableClientsSlice['isShowDeleteCompanyPopup']>) => { 
            state.isShowDeleteCompanyPopup = action.payload;
        },
        setShowDeleteCompanyContactPopup: (state, action: PayloadAction<InitialTableClientsSlice['isShowDeleteCompanyContacntPopup']>) => { 
            state.isShowDeleteCompanyContacntPopup = action.payload;
        },
        clearCompnay: (state, action: PayloadAction<InitialTableClientsSlice['company']>) => { 
            state.company = {} 
        },
        clearCompnayContact: (state, action: PayloadAction<InitialTableClientsSlice['companyContact']>) => { 
            state.companyContact = {} 
        },
        setSelectedCompanyContact: (state, action: PayloadAction<InitialTableClientsSlice['selectedCompanyContact']>) => { 
            state.selectedCompanyContact = action.payload;
        },
    },
});

const getState = (state: any) => state[name] as InitialTableClientsSlice;

export default {
    ...tableClientsSlice,
    selectors: {
        getState,
    }
}
