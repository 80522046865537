import React from 'react';
import { ReactComponent as ArrowCalendarySVG } from '../../common/icons/arrowCalendar.svg';
import { Button, Flex } from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import styles from './DatePickerCustomField.module.scss';
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarStyles.css";

type Props = {
    value: Date|any,
    onChange: any,
    className?: any,
    isShowTimeSelect?: boolean,
}

registerLocale('ru', ru as any);

export default function DatePickerCustomField(props: Props) {
    //@ts-ignore
    const range = (start: any, end: any) => new Array(end - start).fill().map((d, i) => i + start);
  
    const years = range(1950, getYear(new Date()) + 1);
    const months = [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
    ];

    const handleDate = (data: Date) => props.onChange(data);

    return (
        <Flex className={styles.test}>
            <DatePicker
                locale={"ru"}
                className={props.className?.datePicker ?? styles.datePicker}
                calendarClassName={props.className?.datePickerMounth ?? styles.datePickerMounth}
                // timeClassName={props.className?.datePickerTime ?? styles.datePickerTime}
                closeOnScroll={true}
                showTimeSelect={props.isShowTimeSelect ? true : false}
                timeCaption={"Время"}
                timeIntervals={5}
                dateFormat={props.isShowTimeSelect ? 'dd.MM.yyyy HH:mm:ss' :'dd.MM.yyyy'}
                selected={props.value}
                onChange={handleDate}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                        <Flex justifyContent={'space-between'}>
                            <Button variant={'empty'} onClick={decreaseMonth} transform={'rotate(180deg)'} disabled={prevMonthButtonDisabled}>
                                <ArrowCalendarySVG width={'16px'} height={'16px'}/>
                            </Button>
                            <select 
                                className={styles.select} 
                                value={getYear(date)} 
                                onChange={({ target: { value } }: any) => changeYear(value)}
                            >
                                {years.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select 
                                className={styles.select} 
                                value={months[getMonth(date)]} 
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                            >
                                {months.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <Button variant={'empty'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <ArrowCalendarySVG width={'16px'} height={'16px'}/>
                            </Button>
                        </Flex>
                    )
                }
            />
        </Flex>
    )
}