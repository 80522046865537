import { ChangeEvent } from 'react';
import { PropsField } from '..';
import EditInputOrTextField from '../../../../../components/EditInputOrTextField'

export default function TextInputField(props: PropsField) {
    const handleField = (e: ChangeEvent<HTMLInputElement>) => props.field.onChange(e.currentTarget.value);

    return (
        <EditInputOrTextField 
            bg={props.bg}
            id={props.id} 
            value={props.field.value ?? ''} 
            onChange={handleField} 
            title={props.title}
        />
    )
}