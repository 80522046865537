import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TypeCompanyTasksOrStatus } from '../types';
import { Option } from '../../../common/utils'

const name = 'calendarTasksOrStatusesSlice' as 'calendarTasksOrStatusesSlice';

export type InitialCalendarTasksOrStatusesSlice = {
    companyTaskOrStatus: TypeCompanyTasksOrStatus|null,
    companyTasksOrStatuses: TypeCompanyTasksOrStatus[],
    companyTasksOrStatusesOptions: Option[],
};

const initialState: InitialCalendarTasksOrStatusesSlice = {
    companyTaskOrStatus: {},
    companyTasksOrStatuses: [],
    companyTasksOrStatusesOptions: [],
}; 

const calendarTasksOrStatusesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setCompanyTaskOrStatus: (state, action: PayloadAction<InitialCalendarTasksOrStatusesSlice['companyTaskOrStatus']>) => { 
            state.companyTaskOrStatus = { ...state.companyTaskOrStatus, ...action.payload } 
        },
        setCompanyTasksOrStatuses: (state, action: PayloadAction<InitialCalendarTasksOrStatusesSlice['companyTasksOrStatuses']>) => {
            state.companyTasksOrStatuses = action.payload;
        },
        setCompanyTasksOrStatusOptions: (state, action: PayloadAction<InitialCalendarTasksOrStatusesSlice['companyTasksOrStatusesOptions']>) => {
            state.companyTasksOrStatusesOptions = action.payload;
        }
    }
});

const getState = (state: any) => state[name] as InitialCalendarTasksOrStatusesSlice;

export default {
    ...calendarTasksOrStatusesSlice,
    selectors: {
        getState,
    }
}
