import React from 'react';
import { Option } from '../../../common/utils';
import { Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import BaseSelect from '../../../components/BaseSelect';

type Props = {
  field: any,
}

export default function TaskOrStatusCompanyField(props: Props) {
  const { companyTasksOrStatusesOptions} = useSelector((state: RootState) => state.calendarTasksOrStatusesSlice);
  const handleCompany = (company: Option|any) => props.field.onChange(company?.value);
 
  return (
    <Flex flexDirection={'column'}>
      <Text color={'var(--color-grayBlack)'} fontWeight={600}>Клиент</Text>
      <BaseSelect
        width={'320px'}
        menuWidth={'320px'}
        options={companyTasksOrStatusesOptions ?? []}
        value={props.field.value ?? ''}
        onChange={handleCompany}
        placeholder={'Выберите клиента'}
      />
    </Flex>
  )
}
