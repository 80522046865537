import React, { useCallback, useState } from 'react';
import DeveloptmentStages from './blocks/DeveloptmentStages';
import OurPhilosphy from './blocks/OurPhilosphy';
import ContactPopup from './popups/ContactPopup';
import MostAdvanced from './blocks/MostAdvanced';
import OurServices from './blocks/OurServices';
import Contacts from './blocks/Contacts';
import WhoAreWe from './blocks/WhoAreWe';
import Landing from './blocks/Landing';
import Footer from './blocks/Footer';
import Header from './blocks/Header';
import WhyUs from './blocks/WhyUs';
import styles from './Website.module.css';

export type PropsContactPopup = {
  showContactPopup: () => void,
}

export default function Website() {  
  const [isShowContactPopup, setShowContactPopup] = useState(false);
  const handleShowContactPopup = useCallback(() => setShowContactPopup(prev => !prev), []);

  return (
    <div className={styles.wrapper}>
      <Header showContactPopup={handleShowContactPopup} />
      <Landing /> 
      <WhoAreWe showContactPopup={handleShowContactPopup} />
      <OurServices />
      <DeveloptmentStages />
      <OurPhilosphy />
      <MostAdvanced />
      <WhyUs showContactPopup={handleShowContactPopup} />
      <Contacts />
      <Footer />
      <ContactPopup open={isShowContactPopup} setOpen={setShowContactPopup} />
    </div>
  )
}