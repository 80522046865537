import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Flex } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/ru';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

type Props = {
    events: any[],
    handleSelected?: any,
};

const changeTitleButtons = { 
    next:"Следующий", 
    previous:"Предыдущий", 
    today:"Сегодня", 
    month: "Месяц",
    week: "Неделя",
    day: "День",
    agenda: "Повестка дня",
    noEventsInRange: "Нет событии",
};

const styleCalendar = {
    height: '70vh', 
    width: '1200px',
    border: 'none',
};

const activeStyleCalendar = (event: any, start: any, end: any, isSelected: boolean) => {
    let newStyle = {
        margin: '1px',
        backgroundColor: "var(--color-grayWhit)",
        color: 'var(--color-white)',
        borderRadius: "5px",
        border: '1px solid var(--color-grayBlack)',
        display: 'block'
    };
        
    if (event.type === 'status') newStyle.backgroundColor = "var(--color-grayWhite)";
    if (event.type === 'task') newStyle.backgroundColor = "var(--color-purple)";
    if (event.is_active === 0) newStyle.display = "none";

    return {
      className: "",
      style: newStyle
    };
  }

export default function MyCustomCalendar(props: Props) {
    const localizer = momentLocalizer(moment);

    return (
        <Flex 
            border={'2px solid var(--color-grayBlack)'}
            color={'var(--color-grayBlack)'}
            borderRadius={'15px'}
            padding={'15px'}
            
        >
            <Calendar
                style={styleCalendar}
                messages={changeTitleButtons} 
                localizer={localizer}
                events={props.events}
                startAccessor={"start"}
                endAccessor={"end"}
                eventPropGetter={activeStyleCalendar}
                onSelectEvent={props.handleSelected}
            />
        </Flex>
    )
}
