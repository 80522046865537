import { ComponentStyleConfig } from "@chakra-ui/react";

export const Radio: ComponentStyleConfig = {
    defaultProps: {
        colorScheme:'pink'
    },
    baseStyle: {
        borderRadius: '8px',
        fontWeight: 'normal',
    },
    variants: {
        'base': {
            color: 'var(--color-grayBlack)',
            _focus: {
                boxShadow: 'none' 
            } 
        }
    }
}