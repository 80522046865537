import React, { useEffect, useState } from 'react';
import { Option, transformToArray } from '../../../../common/utils';
import { Controller, useForm } from 'react-hook-form';
import { Button, Flex, Text } from '@chakra-ui/react';
import { OurTeam } from '../../../../common/axios';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { TypeTeam } from '../../types';
import TextInputField from './fields/TextInputField';
import LinkInputField from './fields/LinkInputField';
import TextareaField from './fields/TextareaField';
import SelectField from './fields/SelectField';
import teamSlice from '../../slices/teamSlice';
import PhoneField from './fields/PhoneField';
import DateField from './fields/DateField';
import toast from 'react-hot-toast';

export type PropsField = {
    bg: string,
    field?: any,
    id: number | undefined,
    title: string,
    options: Option[],
    placeholder: string,
}

export default function OurMember() {
    const { id } = useParams();
    const dispatch = useDispatch(); 
    const [member, setMember] = useState<TypeTeam>({ create_datetime: new Date(), birthday: String(new Date()) });
    const [countryOptions, setCountryOptions] = useState([]);
    const [statusesTeamOptions, setStatusesTeamOptions] = useState([]);
    const [_cookie] = useCookies([process.env.REACT_APP_COOKIE_USER!]);
 
    const { handleSubmit, control } = useForm({
        defaultValues: member,
        values: member
    });

    const checkedId = id === 'new-member' ? undefined : Number(id); 

    const getMember = (id: number | string) => {
        if (!id) return null;

        OurTeam.post('/get_member', { id })
        .then(({ data }) => {  
            const {options, member } = data;
            setMember(member);
            setCountryOptions(transformToArray(options.countriesOptions) as []);
            setStatusesTeamOptions(transformToArray(options.statusesTeamOptions) as []);
        })
        .catch((err) => console.log(err))
    };

    const submitForm = (taskOrStatus: TypeTeam) => {    
        OurTeam.post('/save', { 
            teamMember: taskOrStatus ?? {},
            craeteUserId: _cookie?._UCAR?.id ?? 0,
        })
        .then(({ data }) => {
            const { message, team } = data;

            dispatch(teamSlice.actions.setTeam(team));
            toast.success(message);
        })
        .catch(err => toast.error(err.response.data));
    };

    useEffect(() => {
        if (!id) {
            dispatch(teamSlice.actions.clearMember({}));
            return;
        } else if (id === 'new-member') {
            getMember(id); 
            return;
        } else {
            getMember(id); 
        }
    }, [id]);

    const nameProps = { bg: 'var(--color-gray)', id: checkedId, title: 'Имя' } as PropsField;
    const countryProps = { bg: 'var(--color-grayWhite)', id: checkedId, title: 'Страна', options: countryOptions, placeholder: 'Выберите страну' } as PropsField;
    const specialityProps = { bg: 'var(--color-gray)', id: checkedId, title: 'Специальность' } as PropsField;
    const statusProps = { bg: 'var(--color-grayWhite)', id: checkedId, title: 'Статус', options: statusesTeamOptions, placeholder: 'Выберите статус' } as PropsField;
    const descriptionProps = { bg: 'var(--color-gray)', id: checkedId, title: 'Описание' } as PropsField;
    const phoneProps = { bg: 'var(--color-grayWhite)', id: checkedId, title: 'Телефон' } as PropsField;
    const socialProps = { bg: 'var(--color-gray)', id: checkedId, title: 'Соц.сети' } as PropsField;
    const hobbyProps = { bg: 'var(--color-grayWhite)', id: checkedId, title: 'Хобби' } as PropsField;
    const birthdayProps = { bg: 'var(--color-gray)', id: checkedId, title: 'День рождения' } as PropsField;
    const commentProps = { bg: 'var(--color-grayWhite)', id: checkedId, title: 'Комментарии' } as PropsField;
    const linkToPortfolioProps = { bg: 'var(--color-gray)', id: checkedId, title: 'Ссылка на портфолио' } as PropsField;

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <Flex  
                w={'840px'} 
                h={'720px'} 
                p={'10px'}
                overflowY={'auto'} 
                borderRadius={'25px'}
                bg={'var(--color-grayWhite)'}
                border={'2px solid var(--color-grayBlack)'} 
                flexDirection={'column'}
            >
                <Text color={'var(--color-grayBlack)'} fontSize={'32px'} mb={'14px'}>Карточка члена команды</Text>
                <Flex flexDirection={'column'}>
                    <Controller
                        control={control}
                        name={'name'}
                        render={ ({ field }) => <TextInputField field={field} { ...nameProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'country'}
                        render={ ({ field }) => <SelectField field={field} { ...countryProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'speciality'}
                        render={ ({ field }) => <TextInputField field={field} { ...specialityProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'status'}
                        render={ ({ field }) => <SelectField field={field} { ...statusProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'description'}
                        render={ ({ field }) => <TextareaField field={field} { ...descriptionProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'phone'}
                        render={ ({ field }) => <PhoneField field={field} { ...phoneProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'social'}
                        render={ ({ field }) => <LinkInputField field={field} { ...socialProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'hobby'}
                        render={ ({ field }) => <TextInputField field={field} { ...hobbyProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'birthday'}
                        render={ ({ field }) => <DateField field={field} { ...birthdayProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'comment'}
                        render={ ({ field }) => <TextareaField field={field} { ...commentProps } /> }
                    />
                    <Controller
                        control={control}
                        name={'link_to_portfolio'}
                        render={ ({ field }) => <LinkInputField field={field} { ...linkToPortfolioProps } /> }
                    />
                </Flex>
            </Flex>
            <Flex justifyContent={'flex-end'}>
                <Button 
                    margin={2}
                    type={'submit'} 
                    fontWeight={'600'} 
                    borderColor={'var(--color-purple)'} 
                    fontSize={'17px'} 
                    variant="standard"
                >
                    Сохранить
                </Button> 
            </Flex>
        </form> 
    )
}