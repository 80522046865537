import config from '../them/config';
import axios from 'axios';

// const API = config.API_URL_GLOBAL;
const API = config.API_URL_LOCAL;

export const AuthUser = axios.create({
    baseURL: `${API}/auth`,
    withCredentials: true
});

export const OurTeam = axios.create({
    baseURL: `${API}/team`,
});

export const ResourceUser = axios.create({
    baseURL: `${API}/resource`
});

export const Company = axios.create({
    baseURL: `${API}/company`,
});

export const CompanyContacts = axios.create({
    baseURL: `${API}/company_contacts`,
});

export const CompanyTaskOrStatuses = axios.create({
    baseURL: `${API}/company_tasks_or_statuses`,
});