import { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../common/icons/arrowLeft.svg';
import { ReactComponent as CalendarIcon } from '../../common/icons/calendar.svg';
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../common/icons/menu.svg';
import { ReactComponent as TeamIcon } from '../../common/icons/team.svg';
import { setLogged } from '../../pages/Login/slice/loginSlice';
import { Flex, Button, Text, Tooltip } from '@chakra-ui/react';
import { AuthUser } from '../../common/axios';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import isMemoryJWT from '../../services/isMemoryJWTService';

export default function Layout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const LINK_NAME = process.env.REACT_APP_LINK_NAME;
    const [showButtonGoBack, setShowButtonGoBack] = useState(true);
    const [cookiesLogged, _logged, removeCookieLogged] = useCookies([process.env.REACT_APP_COOKIE_LOGGED!]);
    const [cookiesUser, _user, removeCookieUser] = useCookies([process.env.REACT_APP_COOKIE_USER!]);
  
    const handleLogout = () => {
        AuthUser.post('/logout')
        .then(() => {
            dispatch(setLogged(false));
            removeCookieLogged(process.env.REACT_APP_COOKIE_LOGGED!);
            removeCookieUser(process.env.REACT_APP_COOKIE_USER!);
            isMemoryJWT.deleteToken();
        })
        .then(() => navigate(`/${LINK_NAME}/sing-in`))
        .catch(err => console.log(err.message))
    }

    const isShowMenuPanel = cookiesLogged._LCAR && pathname.includes(LINK_NAME!);
    const setActiveNaLink = ({ isActive }: { isActive: boolean }) => isActive ? 'activeNavLink' : '';
    const goBack = () => navigate(-1);

    const links = [
        { componentIcon: <MenuIcon className='linkIconActive'/>, to: '/clients' },
        { componentIcon: <Flex padding={'0 1px'} border={'3px solid var(--color-purple)'}><CalendarIcon className='linkIconActive'/></Flex> , to: '/calendar' },
        { componentIcon: <TeamIcon className='linkIconActive'/>, to: '/team' },
    ];

    useEffect(() => {
        const notShowPage = links.map((link: any) => `/${LINK_NAME}${link.to}`);

        if (notShowPage.includes(pathname)) setShowButtonGoBack(false);
        else setShowButtonGoBack(true);
    }, [pathname]);

    return (
        <Flex alignItems={'center'} flexDirection={'column'}>
            {isShowMenuPanel && <Flex w={'100%'} justifyContent={'space-between'} padding={'30px'}>
                <Flex columnGap={5} alignItems={'center'} w={'175px'}>
                    {links.map(link => (
                        <NavLink key={link.to} to={`${LINK_NAME}${link.to}`} className={setActiveNaLink}>
                            {link.componentIcon}
                        </NavLink>
                    ))}
                    {showButtonGoBack && <Tooltip bg={'black'} label={'Назад'}><ArrowLeftIcon cursor={'pointer'} width={'100px'} height={'25px'} onClick={goBack}/></Tooltip> } 
                </Flex>
                <Flex>
                    <Button w={'165px'} h={'45px'} onClick={handleLogout} variant={'standard'}>
                        <Text fontSize={'27px'} pb={'2px'}>Выход</Text>
                    </Button>
                </Flex>
            </Flex>}
            <Flex width={'100%'} justifyContent={'center'}>
                <Outlet/>
            </Flex>
        </Flex>
    )
}
