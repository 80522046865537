import React from 'react';
import styles from './Description.module.css';

export default function Description() {
  return (
    <p className={styles.description}>
      Учитывая наш опыт, мы не верим в универсальное решение для всех и <br/> 
      всегда доступны для живого общения, чтобы найти ту самую идею, <br/>
      которая будет полезна вашему бизнесу.
    </p>
  )
}