import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import s from './DeveloptmentStages.module.css';

type TypeListStages = {
    number: number,
    title: string,
    text: string,
};

const listStages: TypeListStages[] = [
    { number: 1, title: 'Исследования', text: 'Определение целей проекта, анализирование требований заказчика, формирование функциональных и нефункциональных требований к программному продукту' },
    { number: 2, title: 'Выбор технологий', text: 'Выбор технологий, которые наилучшим образом соответствуют требованиям проекта' },
    { number: 3, title: 'UI/UX Дизайн', text: 'Разработка пользовательского интерфейса, включая внешний вид и взаимодействие с пользователем' },
    { number: 4, title: 'Разработка', text: 'Определение архитектуры программного решения, создание программного кода согласно спецификации и дизайну' },
    { number: 5, title: 'Тестирование', text: 'Полное тестирование решений для проверки функциональности, производительности и безопасности' },
    { number: 6, title: 'Внедрение', text: 'После успешного прохождения тестирования программное обеспечение разворачивается на целевой среде' },
    { number: 7, title: 'Поддержка', text: 'Программное обеспечение поддерживается и обновляется в соответствии с изменяющимися требованиями пользователей' },
]; 

export default function DeveloptmentStages() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    
    const textAnimation = {
        hidden: {
            x: -100,
            opacity: 0,
        },
        visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            transition: {  
                duration: .7,
                delay: custom / 12
            }
        })
    };

    const listAnimation = {
        hidden: {
            x: isInView ? 0 : 10,
            opacity: isInView ? 1 : 0,
        },
        visible: (i: number) => ({
            x: 0,
            opacity: 1,
            transition: { 
                delay: i *.3,
            }
        }),
    };

    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <motion.div 
                    className={s.title}
                    variants={textAnimation}
                    initial='hidden'
                    whileInView='visible'
                >
                    <p className={s.titleOne}>ЭТАПы</p>
                    <p className={s.titleTwo}>разработки</p> 
                </motion.div>
                <ul className={s.listStages} ref={ref}>
                    {listStages.map((itemStage: TypeListStages, index: number) => {
                        return (
                            <motion.li 
                                key={itemStage.number} 
                                className={s.itemStage}
                                variants={listAnimation}
                                initial='hidden'
                                whileInView='visible'
                                custom={index}
                            >
                                <p className={s.itemStageNumber}>
                                    {itemStage.number}.
                                </p>
                                <div className={s.itemStageContent}>
                                    <h4>{itemStage.title}</h4>
                                    <p>{itemStage.text}</p>
                                </div>
                            </motion.li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
};