import { useEffect, useState } from 'react';
import { CompanyTaskOrStatuses } from '../../common/axios';
import { useDispatch, useSelector } from 'react-redux';
import { TypeCompanyTasksOrStatus } from './types';
import { Flex, Button } from '@chakra-ui/react';
import { RootState } from '../../store';
import calendarTasksOrStatusesSlice from './slices/calendarTasksOrStatusesSlice';
import MyCustomCalendar from '../../components/MyCustomCalendar';
import AddTaskOrStatus from './popups/AddTaskOrStatus';

export default function CalendarTaskOrStatus() {
  const dispatch = useDispatch();
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [defaultValuesTaskOrStatus, setDefaultValuesTaskOrStatus] = useState({
    id: null,
    type: 'status',
    is_active: '1',
    text: '',
    appointment_datetime: new Date(),
    company_id: 0,
  });

  const { companyTasksOrStatuses } = useSelector((state: RootState) => state.calendarTasksOrStatusesSlice);
  const handleOpenPopup = () => setOpenPopup(true);

  const handleSelected = (event: any) => { 
    const eventEdited = {
      id: event.id,
      type: event.type,
      is_active: String(event.is_active),
      text: event.title,
      appointment_datetime: event.start,
      company_id: event.company_id,
    }
    setDefaultValuesTaskOrStatus(eventEdited);
    setOpenPopup(true);
  };

  useEffect(() => {
    CompanyTaskOrStatuses.get('/get_all')
    .then(({ data }) => {
      const { allCompanyTasksOrStatuses, optionsCompaines } = data;
      dispatch(calendarTasksOrStatusesSlice.actions.setCompanyTasksOrStatusOptions(optionsCompaines));
      dispatch(calendarTasksOrStatusesSlice.actions.setCompanyTasksOrStatuses(allCompanyTasksOrStatuses));
    })
    .catch((err) => {
      console.log(err)
    })
  }, []);

  const eventsCalendaryTasksOrStatuses = 
    companyTasksOrStatuses?.map((taskOrStatus: TypeCompanyTasksOrStatus) => {
      return ({ 
        id:taskOrStatus.id, 
        start: new Date(taskOrStatus.appointment_datetime ?? ''), 
        end: new Date(taskOrStatus.appointment_datetime ?? ''), 
        title: taskOrStatus.text,
        type: taskOrStatus.type,
        is_active: taskOrStatus.is_active,
        company_id: taskOrStatus.company_id
      })
  }) ?? [];

  return (
    <Flex flexDirection={'column'} rowGap={2}>
      <Flex>
        <Button 
          variant={'fill'} 
          background={'var(--color-gray)'}
          color={'var(--color-grayBlack)'}
          fontSize={'16px'} 
          borderColor={'var(--color-purple)'}
          fontWeight={600}
          onClick={handleOpenPopup}
          _hover={{ background: 'var(--color-gray)' }}
        >
          Добавить задачу или статус
        </Button>
      </Flex>
      <MyCustomCalendar 
        events={eventsCalendaryTasksOrStatuses} 
        handleSelected={handleSelected}
      />
      <AddTaskOrStatus 
        isOpen={isOpenPopup} 
        setOpen={setOpenPopup} 
        defaultValues={defaultValuesTaskOrStatus as any}
        setDefaultValuesTaskOrStatus={setDefaultValuesTaskOrStatus}
      />
    </Flex>
  );
}