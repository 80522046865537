import { ComponentProps, ReactNode } from 'react';
import { 
    Button, 
    ButtonGroup, 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay 
} from '@chakra-ui/react';

type PropsPopup = {
    header: string, 
    isOpen: boolean,
    setOpen: (value: boolean) => void,
    children?: JSX.Element,
    isNotShowCloseBtn?: boolean,

    footer?: ReactNode, 
    cancelButtonProps?: ComponentProps<typeof Button>,
    submitButtonProps?: ComponentProps<typeof Button>,

    // styles
    sizePopup?: string,
    bg?: string,
    border?: string,
    borderRadius?: string,
    height?: string,
    margin?: string,
}

export default function Popup(props: PropsPopup) {
    const bg = props.bg ? props.bg : 'var(--color-gray)';
    const sizePopup = props.sizePopup ? props.sizePopup : 'md';
    const border = props.border ? props.border : '25px';
    const borderRadius = props.borderRadius ? props.borderRadius : ' 2px solid var(--color-purple)'; 
    const height = props.height ? props.height : 'fit-content';
    const isNotShowCloseBtn = props.isNotShowCloseBtn ? false : true;
    const margin = props.margin ? props.margin : '';

    const handleClose = () => props.setOpen(false);

    return (
        <Modal isOpen={props.isOpen} onClose={handleClose} size={sizePopup}>
            <ModalOverlay />
            <ModalContent 
                bg={bg} 
                borderRadius={borderRadius} 
                border={border}
                height={height}
                margin={margin}
            >
                <ModalHeader color={'var(--color-grayBlack)'} fontWeight={'500'} fontSize={'24px'}>
                    {props.header}
                </ModalHeader>
                {isNotShowCloseBtn ? 
                    <ModalCloseButton 
                        _focusVisible={{ backround: 'none',  border: 'none' }} 
                        _focus={{ background: 'none', border: 'none' }} 
                        _active={{ background: 'none', border: 'none' }}
                        _hover={{ background: 'none', border: 'none' }}
                    /> 
                    : 
                    <></>
                }
                <ModalBody>
                    <Flex flexDirection={'column'}>
                        {props.children}
                    </Flex>
                </ModalBody>
                <ModalFooter columnGap={2}>
                {props.footer ??
                    <ButtonGroup>
                        <Button variant="standard" onClick={handleClose} {...props.cancelButtonProps}>
                            Отменить
                        </Button>
                        <Button variant="standard" {...props.submitButtonProps}>
                            Сохранить
                        </Button>
                    </ButtonGroup>}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}