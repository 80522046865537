import React from 'react';
import { Element } from 'react-scroll';
import iconOurServiceOneMini from '../../assets/icons/OurServiceOneMini.svg';
import iconOurServiceTwoMini from '../../assets/icons/OurServiceTwoMini.svg';
import iconOurServiceThreeMini from '../../assets/icons/OurServiceThreeMini.svg';
import iconOurServiceFourMini from '../../assets/icons/OurServiceFourMini.svg';
import iconOurServiceFiveMini from '../../assets/icons/OurServiceFiveMini.svg';
import iconOurServiceSixMini from '../../assets/icons/OurServiceSixMini.svg';
import s from './OurServices.module.css';

type TypeService = {
    id: number,
    title: string,
    icon: string,
    text: string,
}

export default function OurServices() {
    const services = [
        { id: 1, title: 'Развитие бизнеса', icon: iconOurServiceOneMini, text: 'Анализ текущего положения компании, определение целей и разработка стратегии для их достижения. Создание и реализация маркетинговых стратегий, направленных на привлечение новых клиентов и удержание существующих' },
        { id: 2, title: 'UX/UI дизайн', icon: iconOurServiceTwoMini, text: 'Проектирование удобных, привлекательных и функциональных цифровых продуктов: веб-сайты, мобильные приложения, программное обеспечение и другие интерактивные системы' },
        { id: 3, title: 'Мобильная разработка', icon: iconOurServiceThreeMini, text: 'Создание нативных и гибридных мобильных приложений для платформ iOS и Android в зависимости от целевой аудитории и бизнес-стратегии' },
        { id: 4, title: 'Веб-разработка', icon: iconOurServiceFourMini, text: 'Комплексные решения по созданию веб-сайтов и веб-приложений, которые способствуют достижению бизнес-целей и и обеспечивают удобство использования' },
        { id: 5, title: 'CRM системы', icon: iconOurServiceFiveMini, text: 'Услуги разработки CRM (Customer Relationship Management) систем предоставляют комплексные решения для автоматизации и оптимизации взаимодействия компании с клиентами' },
        { id: 6, title: 'ERP системы', icon: iconOurServiceSixMini, text: 'Услуги разработки ERP (Enterprise Resource Planning) систем предоставляют интегрированные решения для управления всеми аспектами и ресурсами бизнеса' },
    ];

    return (
        <Element name="services" className={s.wrapper}>
            <div className={s.container}>
                <div className={s.title}>
                    <span className={s.titleOne}>НАШИ </span> 
                    <span className={s.titleTwo}> услуги</span>
                </div>
                <ul className={s.listServices}>
                    {services.map((service: TypeService) => {
                        const isServiceItemReverse = service.id % 2 ? null: s.itemServiceReverse;
                        const isItemServiceImgReverse = service.id % 2 ? null : s.itemServiceImgReverse;

                        return (
                            <li key={service.id} className={`${s.itemService} ${isServiceItemReverse}`}>
                                <div className={s.itemServiceImg}>
                                    <div className={`${s.img} ${s.sizeImg} ${isItemServiceImgReverse}`} />
                                </div>
                                <div className={s.itemServiceContent}>
                                    <img src={service.icon} alt='icon' />
                                    <h4>{service.title}</h4>
                                    <p>{service.text}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Element>
    )
}