import React, { useEffect } from 'react';
import { setLogged, setAppReady } from './pages/Login/slice/loginSlice';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthUser, ResourceUser } from './common/axios';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Flex } from '@chakra-ui/react';
import CalendarTaskOrStatus from './pages/CalendarTaskOrStatus';
import InfoClient from './pages/Clients/pages/InfoClient';
import isMemoryJWT from './services/isMemoryJWTService';
import OurMember from './pages/Team/pages/OurMember';
import RequireAuth from './common/hoc/RequireAuth';
import config from './common/them/config';
import Layout from './components/Layout';
import Toster from './components/Toast';
import NotFound from './pages/NotFound';
import Website from './pages/Website';
import Clients from './pages/Clients';
import Login from './pages/Login';
import Team from './pages/Team';
import './App.css';

ResourceUser.interceptors.request.use((config: any) => {
  const accessToken = isMemoryJWT.getToken();
  if (!accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
 
  return config;
}, (error) => {
  Promise.reject(error)
});

export default function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_COOKIE_LOGGED!]);
  const LINK_NAME = process.env.REACT_APP_LINK_NAME;

  useEffect(() => {
    if (pathname !== '/') {
      // AuthUser.post('/refresh')
      // .then((({ data }) => {
      //   const { accessToken, accessTokenExpiration } = data;
      //   isMemoryJWT.setToken(accessToken, accessTokenExpiration);
      //   dispatch(setAppReady(true));
      //   dispatch(setLogged(true));
      //   setCookie(process.env.REACT_APP_COOKIE_LOGGED!, accessToken);
      // }))
      // .catch(() => {
      //   dispatch(setAppReady(true));
      //   dispatch(setLogged(false));
      // })
    }   
  }, [pathname]);

  useEffect(() => {
    const handlePersistedLogOut = (event: any) => {
      if (event.key === config.LOGOUT_STORAGE_KEY) {
        isMemoryJWT.deleteToken();
        dispatch(setLogged(false));
      };
    };

    window.addEventListener('storage', handlePersistedLogOut);
    return () => window.removeEventListener('storage', handlePersistedLogOut);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;

    if (pathname === '/') body.style.background = 'var(--color-stars)';
    else body.style.background = 'var(--color-whitePink)';
  }, [pathname]);
  
  return (
    <Flex width={'100%'} flexDirection={'column'}>
      <Routes>
        <Route path={'/'} element={<Layout/>}>
          <Route index element={<Website/>}/>
          <Route path={`${LINK_NAME}/clients/:id`} element={<RequireAuth><InfoClient/></RequireAuth>}/>
          <Route path={`${LINK_NAME}/team`} element={<RequireAuth><Team/></RequireAuth>}/>
          <Route path={`${LINK_NAME}/team/:id`} element={<RequireAuth><OurMember/></RequireAuth>}/>
          <Route path={`${LINK_NAME}/clients`} element={<RequireAuth><Clients/></RequireAuth>}/>
          <Route path={`${LINK_NAME}/calendar`} element={<RequireAuth><CalendarTaskOrStatus/></RequireAuth>}/>
          <Route path={`${LINK_NAME}/sing-in`} element={<Login/>}/>
          <Route path={`${LINK_NAME}/*`} element={<NotFound/>}/>
          <Route path={'/*'} element={<NotFound/>}/>
        </Route>
      </Routes>
      <Toster/>
    </Flex>
  );
}