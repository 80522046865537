import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditInputOrLinkField from '../../../../../../../components/EditInputOrLinkField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function WebsiteField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleWebsite = (e: ChangeEvent<HTMLInputElement>) => dispatch(tableClientsSlice.actions.setCompany({ website: e.currentTarget.value }));

    return (
        <EditInputOrLinkField 
            bg={props.bg}
            id={company?.id} 
            value={company?.website} 
            onChange={handleWebsite} 
            title={'Сайт'}
        />


        
  )
}
