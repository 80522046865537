import React, { useEffect } from 'react';
import { Company } from '../../../../common/axios';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import tableClientsSlice from '../../slices/tableClientsSlice';
import CardClient from './components/CardClient';
import SaveClient from './components/SaveClient';
import StatusTask from './components/StatusTask';
import Contacts from './components/Contacts';
import _ from 'lodash';

export default function InfoClient() {
    const { id } = useParams();
    const dispatch = useDispatch();    
    
    const getCompany = (id: number|string) => {
        if (!id || id === 'new-company') return null;

        Company.post('/get_company', { id })
        .then(({ data }) => {  
            const { company, companyContacts, companyTasksOrStatuses } = data;
            dispatch(tableClientsSlice.actions.setCompany(company));
            dispatch(tableClientsSlice.actions.setCompanyContacts(companyContacts));
            dispatch(tableClientsSlice.actions.setCompanyTasksOrStatuses(companyTasksOrStatuses));
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => { 
        if (!id || id === 'new-company') {
            dispatch(tableClientsSlice.actions.clearCompnay(null));
            dispatch(tableClientsSlice.actions.setCompanyContacts([]));
            dispatch(tableClientsSlice.actions.setCompanyTasksOrStatuses([]));
            return;
        } 
        getCompany(id as any); 
    }, [id]);

    return (
        <Flex padding={'0 30px'} flexDirection={'column'}>
            <Flex flexDirection={'column'} width={'100%'}>
                <Flex justifyContent={'center'} columnGap={3}>
                    <Flex flexDirection={'column'} rowGap={3}>
                        <CardClient/>
                        <Contacts/>
                    </Flex>
                    <StatusTask/>
                </Flex>
                <Flex  justifyContent={'flex-end'} mt={2}>     
                    <SaveClient/>
                </Flex>
            </Flex>
        </Flex>
    )
}