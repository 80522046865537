import React, { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { propsField } from '..';
import EditTextareaOrLinkField from '../../../../../../../components/EditTextareaOrLinkField';
import tableClientsSlice from '../../../../../slices/tableClientsSlice';

export default function SocialField(props: propsField) {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.tableClientsSlice);
    const handleSocial = (e: ChangeEvent<HTMLTextAreaElement>) => dispatch(tableClientsSlice.actions.setCompany({ social: e.currentTarget.value }));

    return (
        <EditTextareaOrLinkField
            bg={props.bg}
            id={company?.id}
            title={'Соц. сети'}
            value={company?.social}
            onChange={handleSocial}
        />
    )
}
  