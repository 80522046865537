export const Input = {
    variants: {
        base: {
            field: {
                bg: 'transparent', 
                border: '2px solid var(--color-purple)', 
                background: 'var(--color-grayWhite)',
                borderRadius: '25px',
                paddingLeft: '15px',
                fontSize: '14px',
                color: 'var(--color-grayBlack)',
                _focusVisible: {
                    outlineOffset: 0, 
                    outline: 0 
                },
                _placeholder: {
                    color: 'var(--color-grayBlack)'
                },
                _disabled: {
                    bg: 'var(--color-gray)',
                    color: 'var(--color-gray)',
                },
            },
        },
        lineBottom: {
            field: {
                bg: 'transparent', 
                borderBottom: '2px solid var(--color-grayBlack)', 
                borderRadius: '0px',
                paddingLeft: '5px',
                fontSize: '22px !important',
                color: 'var(--color-grayBlack) !important',
                _focusVisible: {
                    outlineOffset: 0, 
                    outline: 0,
                },
                _focus: {
                    bg: 'transparent', 
                },
                _placeholder: {
                    color: 'var(--color-grayBlack)',
                },
                _disabled: {
                    bg: 'var(--color-gray)',
                    color: 'var(--color-gray)',
                },
                _autofill: {
                    textFillColor: 'var(--color-grayBlack)',
                    transition: 'background-color 5000s ease-in-out 0s',
                    textFillFontSize: '20px'
                },
                _autofocus : {
                    color: 'transparent',
                    fontSize: '18px',
                }
            },
        },
        ghost: {
            field: {
                bg: 'transparent', 
                border: 'none',
                paddingLeft: '10px',
                fontSize: '18px !important',
                color: 'var(--color-grayBlack) !important',
                _focusVisible: {
                    outlineOffset: 0, 
                    outline: 0,
                },
                _focus: {
                    bg: 'transparent', 
                },
                _placeholder: {
                    color: 'var(--color-grayBlack)',
                },
                _disabled: {
                    bg: 'var(--color-gray)',
                    color: 'var(--color-gray)',
                },
                _autofill: {
                    textFillColor: 'var(--color-grayBlack)',
                    transition: 'background-color 5000s ease-in-out 0s',
                    textFillFontSize: '20px'
                },
                _autofocus : {
                    color: 'transparent',
                    fontSize: '18px',
                }
            },
        },
    },
}