import React from 'react';
import { Link } from 'react-scroll';
import s from './Footer.module.css';

type ListLink = {
  id: number,
  text: string,
  to: string,
}

export default function Footer() {
  const listLinks: ListLink[] = [
    { id: 1, text: 'Услуги', to: 'services' },
    { id: 2, text: 'О нас', to: 'aboutUs' },
    { id: 3, text: 'Контакты', to: 'contacts' },
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <p>© 2024 Идейное бюро #Один</p>
        <div className={s.listLinks}>
          {listLinks.map(link => {
            return (
              <Link key={link.id} to={link.to} smooth={true} duration={1600}>
                {link.text}
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}
 