import React from 'react';
import { PropsField } from '..';
import EditPnoneOrTextField from '../../../../../components/EditPnoneOrTextField';
import styles from './fieldsStyles/PhoneStyleField.module.scss';

export default function PhoneField(props: PropsField) {
    const handlePhone = (e: any) => props.field.onChange(e);

    return (
        <EditPnoneOrTextField 
            bg={props.bg}
            id={props.id} 
            title={props.title}
            value={props.field.value} 
            onChange={handlePhone} 
            containerClass={styles}
        />
    )
}
