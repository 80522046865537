import React from 'react';
import { Element } from 'react-scroll';
import s from './Contacts.module.css';

export default function Contacts() {
  return (
    <Element name="contacts" className={s.wrapper}>
        <div className={s.container}>
            <div className={s.title}>
                <p className={s.titleOne}>Обсудим ваши</p>
                <p className={s.titleTwo}>идеи?</p>
            </div>
            <p className={s.description}>
                Учитывая наш опыт, мы не верим в универсальное решение для всех и всегда 
                доступны для живого общения, чтобы найти ту самую идею, которая будет полезна вашему бизнесу. 
            </p>
            <p className={s.welcome}>Будем рады начать наше общение!</p>
            <div className={s.contacts}>
                <a className={s.phone} href='tel:+(375) 29 311 79 70'>
                    +(375) 29 311 79 70
                </a>
                <a className={s.email} href='mailto:ekaterina@buronomerodin.by'>
                    ekaterina@buronomerodin.by
                </a>
            </div>
        </div>
    </Element> 
  )
}
