import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Flex, Text } from '@chakra-ui/react';
import PhoneCustomField from '../../../../../components/PhoneCustomField';
import tableClientsSlice from '../../../slices/tableClientsSlice';

export default function PhoneField() {
    const dispatch = useDispatch();
    const { companyContact } = useSelector((state: RootState) => state.tableClientsSlice);
    const handlePhone = (e: string) =>  dispatch(tableClientsSlice.actions.setCompanyContact({ phone: e }));

    return (
        <Flex flexDirection={'column'} w={'280px'}>
            <Text fontWeight={'bold'} color={'var(--color-grayBlack)'}>Телефон:</Text>
            <PhoneCustomField 
                onlyCountries={['by', 'ru', 'kz', 'uz']}
                value={companyContact?.phone ?? ''} 
                onChange={handlePhone} 
            />
        </Flex>
    )
}
