import React, { useEffect } from 'react';
import { PropsContactPopup } from '../..';
import s from './WhyUs.module.css';

type TypeListAdvantages = {
    number: string,
    title: string,
    text: string,
};

const listAdvantages: TypeListAdvantages[] = [
    { number: '01', title: 'Экспертиза и профессионализм', text: 'Наша команда обладает богатым опытом и глубокими знаниями в области веб-разработки, мобильной разработки и дизайна, что позволяет нам предлагать профессиональные решения' },
    { number: '02', title: 'Индивидуальный подход', text: 'Мы понимаем, что каждый бизнес уникален. Поэтому мы разрабатываем индивидуальные решения, учитывая потребности и цели каждого клиента' },
    { number: '03', title: 'Качество и инновации', text: 'Мы стремимся к постоянному совершенствованию и применяем последние технологии и тренды в нашей работе, чтобы обеспечить вам конкурентное преимущество' },
    { number: '04', title: 'Прозрачность и коммуникация', text: 'Мы регулярно информируем вас о ходе работы, обсуждаем все важные решения и стараемся сделать процесс сотрудничества максимально комфортным ' },
];

export default function WhyUs(props: PropsContactPopup) {

    useEffect(() => {
        const textCircle = document.querySelector("#textCircle") as HTMLDivElement;
        if (textCircle) {
            textCircle.innerHTML = textCircle.innerText
            .split("")
            .map((char, i) => `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`)
            .join("");
        }          
    }, []);

    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <h2 className={s.title}>Почему Мы?</h2>
                <p className={s.description}>
                    Мы создаем оригинальные и инновационные решения, которые помогают<br/>
                    выделиться на рынке и привлечь внимание вашей целевой аудитории
                </p>
                <ul className={s.listAdvantages}>
                    {listAdvantages.map(itemAdvantage => {
                        return (
                            <li key={itemAdvantage.number} className={s.itemAdvantage}>
                                <div className={s.itemAdvantageTitle}>
                                    <span>{itemAdvantage.number}</span>
                                    <p>{itemAdvantage.title}</p>
                                </div>
                                <p className={s.itemAdvantageDescription}>
                                    {itemAdvantage.text}
                                </p>
                            </li>
                        )
                    })}
                </ul>
                <div className={s.discuss}>
                    <div className={s.blockButton} onClick={props.showContactPopup}>
                        <div className={s.circle}>
                            <div className={s.arrow}></div>
                            <div className={s.text} id={'textCircle'}>
                                <p>
                                    Обсудить идею  &nbsp;&#9642;&nbsp; Обсудить идею  &nbsp;&#9642;&nbsp;
                                </p>
                            </div>
                        </div>          
                    </div>
                </div>  
            </div>
        </div>
    )
}