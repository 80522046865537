import { useEffect, useState } from 'react';
import Description from './components/Description';
import Popup from '../../../../components/Popup';
import Feedback from './components/Feedback';
import Logo from './components/Logo';
import styles from './ContactPopup.module.css';

export type PropsPopup = {
  open: boolean,
  setOpen: (value: boolean) => void;
}

export default function ContactPopup(props: PropsPopup) {
  const [windowWidth, setWindowWidth] = useState(window.screen.width);

  const handleClose = () => props.setOpen(false);

  useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.screen.width)
    };    
    return () => {(window as any).onresize = false};
  }, [windowWidth]);

  const sizePopup = () => {
    if (Number(windowWidth) <= 910) return 'lg';
    if (Number(windowWidth) < 710) return 'md';
    if (Number(windowWidth) < 450) return 'sm';

    return '2xl';
  }

  return (
    <Popup
      header={''}
      isOpen={props.open}
      setOpen={handleClose}
      bg={'var(--color-beige)'}
      sizePopup={sizePopup()}
      margin={'25px'}
      height={'580px'}
      footer={<></>}
    >
     <div className={styles.containerFormPopup}>
        <Logo/>
        <Description />
        <Feedback />
     </div>
    </Popup>
  )
}