import React from 'react';
import { Flex, Button, ButtonGroup } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { TypeCompanyContact } from '../../types';
import { RootState } from '../../../../store';
import TextareaTextField from './fields/components/TextareaTextField';
import InputTextField from './fields/components/InputTextField';
import tableClientsSlice from '../../slices/tableClientsSlice';
import BirthdayField from './fields/BirthdayField'
import Popup from '../../../../components/Popup';
import CountryField from './fields/CountryField';
import PhoneField from './fields/PhoneField';

export default function AddOrUpadateCompanyContactPopup() {
    const dispatch = useDispatch();
    const { company, companyContact, companyContacts, selectedCompanyContact, isShowAddOrUpadateCompanyContact } = useSelector((state: RootState) => state.tableClientsSlice)

    const headerPopup = companyContact?.id ? `Редактиоровать контакт в компании ${company?.name}` : `Добавить контакт в компанию ${company?.name}`

    const handleClose = () => {
        dispatch(tableClientsSlice.actions.setShowAddOrUpadateCompanyContactPopup(false));
        dispatch(tableClientsSlice.actions.setSelectedCompanyContact(null));
        dispatch(tableClientsSlice.actions.clearCompnayContact(null));
    };

    const handleAddOrUpdateCompanyContact = () => {   
        if (selectedCompanyContact?.name) {
            const upadateCompanyContact = companyContacts.map((compCont: TypeCompanyContact|any) => compCont.name === selectedCompanyContact?.name ? companyContact : compCont);
            dispatch(tableClientsSlice.actions.setCompanyContacts(upadateCompanyContact))
        } else {
            const addCompanyContact = [...companyContacts ?? [], companyContact];
            dispatch(tableClientsSlice.actions.setCompanyContacts(addCompanyContact as any));
        }
        handleClose(); 
    };
  
    return (
        <Popup
            header={headerPopup}
            isOpen={isShowAddOrUpadateCompanyContact}
            setOpen={handleClose}
            border={'2px solid var(--color-purple)'}
            borderRadius={'15px'}
            sizePopup={'3xl'}
            footer={
                <ButtonGroup>
                    <Button  
                        fontSize={'17px'} 
                        variant={'standard'} 
                        onClick={handleClose}
                    >
                        Отменить
                    </Button>
                    <Button 
                        fontSize={'17px'} 
                        variant={'fill'} 
                        onClick={handleAddOrUpdateCompanyContact}
                    >
                        Сохранить
                    </Button>
                </ButtonGroup>
            }>
            <Flex flexDirection={'column'} rowGap={8} marginBottom={5}>
                <Flex justifyContent={'space-between'}>
                    <InputTextField title={'ФИО'} field={'name'}/>
                    <CountryField/>
                </Flex>
                <Flex justifyContent={'space-between'}>
                    <InputTextField title={'Должность'} field={'position'}/>
                    <InputTextField title={'Роль'} field={'role'}/>
                </Flex>
                <Flex justifyContent={'space-between'}>
                    <PhoneField/>
                    <BirthdayField/>
                </Flex>
                <Flex justifyContent={'space-between'}>
                    <TextareaTextField title={'Комментарии'} field={'comment'}/>   
                    <TextareaTextField title={'Описание'} field={'descrption'}/>   
                </Flex>
                <Flex justifyContent={'space-between'}>
                    <TextareaTextField title={'Соц. сети'} field={'social'}/>   
                    <TextareaTextField title={'Хобби'} field={'hobby'}/>   
                </Flex>
            </Flex>
        </Popup>
    )
}